import { Component, OnInit, Output, Input, EventEmitter, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { SelectPage } from './select.page';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit, OnChanges {

  @Input() selectedValue : any;
  @Input() values : any;
  @Input() title : string;
  @Output() inputModelChange = new EventEmitter<string>();
  @Output() selectedValueChange = new EventEmitter();

  value2show : string = "";

  constructor(
    private navParams: NavParams,
    public modalCtrl: ModalController,
    public translate: TranslateService) {

    this.value2show = this.translate.instant('SEQUENCES.COMPONENT.STEPS.AVATAR-SELECT-PLACEHOLDER');

  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    //console.log("ngOnChanges, changens: ", changes);
    //console.log("ngOnChanges, this.selectedValue=", this.selectedValue);
    if (this.selectedValue && this.selectedValue == -1) {
      this.value2show = this.translate.instant('COMMON.NO-ASSIGNMENT');
    }
    else if ( this.selectedValue && this.values ) {
      for (let value of this.values) {
        if (value.id == this.selectedValue) {
          this.value2show = value.name;
        }
      }
    }
    else {
      this.selectedValue = -1
    }
  }

  async changeValue() {
    let context = this;
    //console.log("changeValue, this.selectedValue=", this.selectedValue);
    const modal = await this.modalCtrl.create({
      component: SelectPage,
      componentProps: {
        'title': this.title,
        'values' : this.values,
        'selected' : this.selectedValue
      },
      backdropDismiss: false,
      cssClass: 'select-modal'
    });

    modal.onDidDismiss().then((data) => {
      if (data && data.data && data.data.selected) {
        //console.log("modal.onDidDismiss, data.data.selected: ", data.data.selected);
        context.selectedValue = data.data.selected;
        console.log("context.values=", context.values);
        for (let value of context.values) {
          if (context.selectedValue && value.id == context.selectedValue) {
            context.value2show = value.name;
          }
        } 
        // context.inputModelChange(context.selectedValue);
        context.selectedValueChange.emit(context.selectedValue);
      }
    });

    return await modal.present();
  }

}

import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastController, LoadingController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
//import { FileOriginal, DirectoryEntry, FileEntry } from '@ionic-native/file';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  private version: string = "v1.0.0.0";
  private versionDate: string = "31.05.2021";

  private toast: HTMLIonToastElement = null;
  private wait: HTMLIonLoadingElement = null;
  private loading: HTMLIonLoadingElement = null;

  constructor(public translate: TranslateService, private toastController: ToastController, public loadingCtrl: LoadingController, public alertController: AlertController) { }

  getAppVersion() {
    return this.version;
  }

  getAppVersionDate() {
    return this.versionDate;
  }

  log(messages: string, consoleOnly: boolean = true) {
    console.log(messages);
  }

  async appInfo() {
    const alert = await this.alertController.create({
      cssClass: 'alert-class',
      header: 'App Info',
      message: 'Version: ' + this.getAppVersion() + " vom " + this.getAppVersionDate(),
      buttons: [
        {
          text: 'Schließen',
          role: 'cancel',
          handler: () => {
          }
        }
      ]
    });

    alert.onDidDismiss().then((data) => {
    });

    await alert.present();
  }

  generateUUID() {
    var d = new Date().getTime();
    if (window.performance && typeof window.performance.now === "function") {
      d += performance.now();; //use high-precision timer if available
    }
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }

  async presentInfo(event: Event, msg: string, duration = 6000) {
    if (event) event.stopPropagation();
    await this.presentToast(msg, duration);
  }

  async presentToast(msg: string, duration = 5000, dismissCallbackFunc: Function = null) {
    if (this.toast) {
      await this.toast.dismiss();
    }
    this.toast = await this.toastController.create({
      message: msg,
      position: 'middle',
      duration: duration
    });
    if (dismissCallbackFunc) {
      this.toast["dismissCallbackFunc"] = dismissCallbackFunc;
    }
    this.toast.onDidDismiss().then(() => {
      if (this.toast["dismissCallbackFunc"]) {
        this.toast["dismissCallbackFunc"]();
      }
      this.toast = null;
    });
    await this.toast.present();
  }

  async dismissToast() {
    console.log("GLOBAL >>>>>>> dismissToast, ", this.toast);
    try {
      this.toast.dismiss();
    }
    catch (e) { console.log("GLOBAL, no this.toast!") };
  }

  async presentWaiter(dismissCallbackFunc: Function = null) {
    if (this.wait) {
      await this.wait.dismiss();
    }
    if (dismissCallbackFunc) {
      this.wait["dismissCallbackFunc"] = dismissCallbackFunc;
    }
    this.wait = await this.loadingCtrl.create({
      cssClass: 'wait-class',
      message: await this.translate.instant('COMMON.WAIT')
    });
    await this.wait.present();

    this.wait.onDidDismiss().then(() => {
      if (this.wait["dismissCallbackFunc"]) {
        this.wait["dismissCallbackFunc"]();
      }
      delete this.wait;
    });
  }

  async dismissWaiter() {
    console.log("GLOBAL >>>>>>> dismissWaiter, ", this.wait);
    try {
      this.wait.dismiss();
    }
    catch (e) { console.log("GLOBAL, no this.wait!") };
  }

  /* ***************************************************************************************** */

  convertFileToDataURLviaFileReader(url: string) {
    console.log("global.service: convertFileToDataURLviaFileReader, url: ", url);
    return new Observable(observer => {
      let xhr: XMLHttpRequest = new XMLHttpRequest();
      xhr.onload = function () {
        let reader: FileReader = new FileReader();
        reader.onloadend = function () {
          observer.next(reader.result);
          observer.complete();
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    });
    /*
    return Observable.create(observer => {
      let xhr: XMLHttpRequest = new XMLHttpRequest();
      xhr.onload = function () {
        let reader: FileReader = new FileReader();
        reader.onloadend = function () {
          observer.next(reader.result);
          observer.complete();
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    });
    */
  }

  convertFileToDataURLviaFileReaderBlob(data: any) {
    console.log("global.service: convertFileToDataURLviaFileReaderBlob, data: ", data);
    return new Observable(observer => {
      let reader: FileReader = new FileReader();
      reader.onloadend = function () {
        observer.next(reader.result);
        observer.complete();
      };
      reader.readAsDataURL(data);
    });
    /*
    return Observable.create(observer => {
      let reader: FileReader = new FileReader();
      reader.onloadend = function () {
        observer.next(reader.result);
        observer.complete();
      };
      reader.readAsDataURL(data);
    });
    */
  }

  base64MimeType(encoded: any) {
    let result: string = null;
    if (typeof encoded !== 'string') {
      return result;
    }
    let mime: string[] = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    if (mime && mime.length) {
      result = mime[1];
    }
    return result;
  }

  async fetchAndBlob(base64: string) {
    let res: Response = await fetch(base64);
    let blob: Blob = await res.blob();
    //console.log("fetchAndBlob: ", blob);
    return blob;
  }

  getFile(data: any | Blob) {
    console.log("global.service: getFile, data: ", data);
    return new Observable(observer => {
      let reader: FileReader = new FileReader();
      reader.onloadend = function () {
        observer.next(reader.result);
        observer.complete();
      };
      reader.readAsDataURL(data);
    });
  }

  getBase64ByFileOrBlob = (data: any | Blob) => new Promise((resolve, reject) => {
    const reader: FileReader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(data);
  });

  b64ToBlob(b64Data: string, contentType: string = "") {
    let sliceSize: number = 512;
    let byteCharacters: string = atob(b64Data);
    let byteArrays: any[] = [];

    for (let offset: number = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice: string = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers: number[] = new Array(slice.length);
      for (let i: number = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      let byteArray: Uint8Array = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    let blob: Blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  blobToFile = (blobData: Blob, fileName: string) => {
    const fd: FormData = new FormData();
    fd.set('a', blobData, fileName);
    return fd.get('a');
  }




  dynamicSort(property: any, lowerCase: boolean = false) {
    let sortOrder: number = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a: any, b: any) {
      // next line works with strings and numbers, and you may want to customize it to your needs
      let valA: any = a[property];
      let valB: any = b[property];
      if (lowerCase && typeof valA == 'string') {
        valA = valA.toLowerCase();
      }
      if (lowerCase && typeof valB == 'string') {
        valB = valB.toLowerCase();
      }
      //let result: number = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      let result: number = (valA < valB) ? -1 : (valA > valB) ? 1 : 0;
      return result * sortOrder;
    }
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { GlobalService } from './../../services/global.service';
import { TranslateService } from '@ngx-translate/core';

import { ImageCropperComponent, ImageTransform, ImageCroppedEvent, Dimensions, base64ToFile } from 'ngx-image-cropper';

// Import Image Cropper
// https://www.npmjs.com/package/ngx-image-cropper
// https://github.com/Mawi137/ngx-image-cropper
// Example:
// https://www.youtube.com/watch?v=UnqVkzWg2W0

@Component({
  selector: 'app-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.scss'],
})
export class ImageEditorComponent implements OnInit {

  @ViewChild(ImageCropperComponent, { static: false }) imageCropper: ImageCropperComponent;
  
  cropperShow: boolean = false;
  cropperTransform: ImageTransform = {}; // flip, rotate and scale the image
  cropperRotation: number = 0;
  cropperScale: number = 1;
  cropperContainWithinAspectRatio: boolean = false;
  cropperCanvasRotation: number = 0; // default: 0, 1 = 90deg, 2 = 180deg, ...
  cropperAutoCrop: boolean = false; // default: true
  cropperImageQuality: number = 92; // defualt: 92
  cropperAspectRation: number = (4 / 3); // default: 1 / 1
  cropperMaintainAspectRatio: boolean = false; // default: true
  cropperOutputFormat: string = "png"; // default: png
  cropperAlignImage: string = "center"; // default: center
  cropperOnlyScaleDown: boolean = true; // default: true
  cropperMinWidth: number = 256; // default: 0 (disabled)
  cropperInitialStepSize: number = 3; // default: 3 (px)

  hideResizeSquares: boolean = true; // default: false

  image64Data: any;  
  image64Cropped: any;

  undoList: any[]; 

  imageEdited: boolean = false;
  steps: number = 0;

  constructor(
    private navParams: NavParams,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    public translate: TranslateService,
    public global: GlobalService) {
    

    this.undoList = [];
    this.imageEdited = false;

    let data = navParams.get("image64");
    if (data) {
      this.global.convertFileToDataURLviaFileReader(data).subscribe(
        base64 => {
          this.image64Data = base64;
          this.image64Cropped = base64;
        }
      );
      //this.image64Data = data;
    }
  }

  ngOnInit() { }

  ionViewDidEnter() {
    console.log(this.imageCropper);
    console.log("this.imageCropper.imageBase64=", this.imageCropper.imageBase64);
    console.log("this.imageCropper.imageURL=", this.imageCropper.imageURL);
  }

  async cancel() {
    this.modalCtrl.dismiss();
  }

  async close() {
    if (this.imageEdited) {
      const alert = await this.alertCtrl.create({
        subHeader: await this.translate.instant('COMMON.ATTENTION'),
        message: await this.translate.instant('COMMON.CLOSE-MESSAGE-SAVE'),
        buttons: [
          {
            text: await this.translate.instant('COMMON.NO'),
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              console.log('Confirm Cancel:', blah);
            }
          }, {
            text: await this.translate.instant('COMMON.YES'),
            handler: () => {
              this.cancel();
            }
          }
        ]
      });

      await alert.present();
    }
    else {
      this.save();
    }
  }
 
  async save() {
    await this.crop();
    this.modalCtrl.dismiss({
      //image64: this.image64Data
      image64: this.image64Cropped
    });
  }

  // -------------------------------------------------------------
  // -------------------------------------------------------------
  // -------------------------------------------------------------

  imageLoaded() {
    this.cropperShow = true;
    console.log('Image loaded');
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
    console.log('Load failed');
  }

  imageCropped(event: ImageCroppedEvent) {
    console.log("imageCropped!");
    //this.image64Data = event.base64;
    this.image64Cropped = event.base64;
  }

  // -------------------------------------------------------------

  async crop() {
    
    this.imageEdited = true;

    //this.undoList.push(this.image64Data);
    this.undoList.push(this.image64Cropped);

    let cropper = this.imageCropper.crop();
    console.log("CROP, cropper: ", cropper);

    //this.image64Cropped = cropper.base64;
  }

  reset(){
    this.cropperScale = 1;
    this.cropperRotation = 0;
    this.cropperCanvasRotation = 0;
    this.cropperTransform = {};
    this.imageCropper.resetCropperPosition();
  }

  undo() {

    this.reset();

    this.image64Data = this.undoList.pop();

    if (this.undoList.length <= 0) {
      this.imageEdited = false;
    }
  }

  // -------------------------------------------------------------

  zoomIn() {
    this.steps++;
    this.cropperScale += .1;
    this.cropperTransform = {
      ...this.cropperTransform,
      scale: this.cropperScale
    };
  }

  zoomOut() {
    this.steps++;
    this.cropperScale -= .1;
    this.cropperTransform = {
      ...this.cropperTransform,
      scale: this.cropperScale
    };
  }

  rotateLeft() {
    this.steps++;
    this.cropperCanvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.steps++;
     this.cropperCanvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.cropperTransform.flipH;
    const flippedV = this.cropperTransform.flipV;
    this.cropperTransform = {
        ...this.cropperTransform,
        flipH: flippedV,
        flipV: flippedH
    };
  }

  flipHorizontal() {
    this.steps++;
    this.cropperTransform = {
      ...this.cropperTransform,
      flipH: !this.cropperTransform.flipH
    };
  }

  flipVertical() {
    this.steps++;
    this.cropperTransform = {
      ...this.cropperTransform,
      flipV: !this.cropperTransform.flipV
    };
  }

  moveCropper(x: number, y: number) {
    this.steps++;
    this.imageCropper.cropper.x1 += x;
    this.imageCropper.cropper.x2 += x;
    this.imageCropper.cropper.y1 += y;
    this.imageCropper.cropper.y2 += y;
  }

  updateRotation() {
    this.cropperTransform = {
      ...this.cropperTransform,
      rotate: this.cropperRotation
    };
  }

  toggleContainWithinAspectRatio() {
    this.cropperContainWithinAspectRatio = !this.cropperContainWithinAspectRatio;
  }

}

import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';

import { MediaComponent } from '../media/media.component';
import { MediaoptionsComponent } from '../../popovers/mediaoptions/mediaoptions.component';

import { ApiService } from '../../services/api.service';
import { DataService } from '../../services/data.service';
import { GlobalService } from '../../services/global.service';

import { environment } from '../../../environments/environment';

import { TranslateService } from '@ngx-translate/core';

import { Folder } from '../../media/media.page';

@Component({
  selector: 'app-filechooser',
  templateUrl: './filechooser.component.html',
  styleUrls: ['./filechooser.component.scss'],
})
export class FilechooserComponent implements OnInit {

  me: any = {};

  objects: any[] = [];
  filter: any[] = [];
  tags: string = "";

  types: string[] = [];
  selected: number = -1;
  filename: string = "";
  selectedType: string = "";
  selectedName: string = "";

  searchInput: string = "";

  showUsers: boolean = true;
  showLocations: boolean = true;
  showSequences: boolean = true;
  showPictograms: boolean = true;
  showAvatars: boolean = true; // false
  showOwners: boolean = false;
  showFolders: boolean = true;
  showImages: boolean = true;
  showVideos: boolean = true;
  showAudios: boolean = false;

  // Folder
  folders: Folder[] = [];
  currentFolder: number = -1; // root
  folderList: any[] = [];

  // Media
  nameList: any[] = [];

  mediaUrl: string = environment.mediaUrl;


  constructor(
    navParams: NavParams,
    public modalCtrl: ModalController,
    public apiSrv: ApiService,
    public dataSrv: DataService,
    public global: GlobalService,
    public translate: TranslateService
  ) {

    let types = navParams.get("types");
    if (types) {
      this.types = types;
    }

    let image = navParams.get("image");
    if (image) {
      this.selected = image;
    }

    this.tags = navParams.get("tags");
    console.log("this.tags:", this.tags);
    /*
    if (this.tags) {
      if (this.tags == 'user') {
        this.showUsers = true;
        this.showLocations = false;
        this.showSequences = false;
        this.showPictograms = false;
        this.showAvatars = false;
      }
      if (this.tags == 'location') {
        this.showUsers = false;
        this.showLocations = true;
        this.showSequences = false;
        this.showPictograms = false;
        this.showAvatars = false;
      }
      if (this.tags == 'sequence') {
        this.showUsers = false;
        this.showLocations = false;
        this.showSequences = true;
        this.showPictograms = true;
        this.showAvatars = true;
      }
    }
    */
    this.initComponent();
  }

  async initComponent() {
    this.me = await this.dataSrv.getItem("me");
    let token = await this.dataSrv.getItem("token");
    let response = await this.apiSrv.doGet('media', { "token": token }, {});
    this.objects = [];

    let filterSettings: any = await this.dataSrv.getItem("mediaFilter_" + this.tags);
    console.log(filterSettings);
    if (filterSettings) {
      this.showUsers = filterSettings.hasOwnProperty("users") ? filterSettings.users : true;
      this.showLocations = filterSettings.hasOwnProperty("locations") ? filterSettings.locations : true;
      this.showSequences = filterSettings.hasOwnProperty("sequences") ? filterSettings.sequences : true;
      this.showPictograms = filterSettings.hasOwnProperty("pictograms") ? filterSettings.pictograms : true;
      this.showAvatars = filterSettings.hasOwnProperty("avatars") ? filterSettings.avatars : true;
      this.showOwners = filterSettings.hasOwnProperty("owners") ? filterSettings.owners : false;
      this.showFolders = filterSettings.hasOwnProperty("folders") ? filterSettings.folders : true;

      this.showImages = filterSettings.hasOwnProperty("showImages") ? filterSettings.showImages : true;
      this.showVideos = filterSettings.hasOwnProperty("showVideos") ? filterSettings.showVideos : true;
      this.showAudios = false; //filterSettings.hasOwnProperty("showAudios") ? filterSettings.showAudios : true;
    }

    if (this.types.indexOf("image") < 0) {
      this.showImages = false;
    }
    if (this.types.indexOf("video") < 0) {
      this.showVideos = false;
    }
    if (this.types.indexOf("audio") < 0) {
      this.showAudios = false;
    }

    let allObjects = JSON.parse(JSON.stringify(response));
    for (let object of allObjects) {
      if (!object.settings) {
        object.settings = {
          "keywords": ""
        }
      }
      
      if (!object.settings.keywords) {
        object.settings.keywords = "";
      }

      for (let objectType of this.types) {
        if (object.type == objectType) {
          this.objects.push(object);
        }
      }

      // Liste aller Ordner, unique...
      /*
      if (object.foldername && object.foldername.length > 0 && !this.folderList.some(e => e === object.foldername)) {
        this.folderList.push(object.foldername);
      }
      */

      // Liste aller bereits vergebenen Mediennamen, unique...
      /*
      if (object.name && object.name.length > 0 && !this.nameList.some(e => e === object.name)) {
        this.nameList.push(object.name);
      }
      */
      if (object.name && object.name.length > 0) {
        this.nameList.push({ id: object.folder, name: object.name });
      }

    }

    console.log("this.nameList: ", this.nameList);

    response = await this.apiSrv.doGet('folders', { "token": token }, {});
    this.folders = JSON.parse(JSON.stringify(response));
    console.log("FOLDERS: ",  this.folders);

    this.folderList = [];
    for (let folder of this.folders) {
      if (folder.name && folder.name.length > 0) {
        this.folderList.push({ id: folder.parent, name: folder.name });
      }
    }
    console.log("this.folderList: ", this.folderList);

    this.filterObjects();
  }

  filterObjects() {
    this.filter = [];

    let temp: any[] = [];

    if (this.searchInput == '') {
      temp = this.objects;
    } else {
      for (let object of this.objects) {
        if (object.name.toLowerCase().indexOf(this.searchInput.toLowerCase()) != -1) {
          temp.push(object);
        }
      }
    }

    for (let object of temp) {
      if ((this.showOwners == true && object.owner == this.me.id) || this.showOwners == false) {

        /*
        object.visible = false;
        if (this.showUsers == true && object.settings.keywords.indexOf("user") != -1) {
          object.visible = true;
        }
        if (this.showLocations == true && object.settings.keywords.indexOf("location") != -1) {
          object.visible = true;
        }
        if (this.showSequences == true && object.settings.keywords.indexOf("sequence") != -1) {
          object.visible = true;
        }
        if (this.showPictograms == true && object.settings.keywords.indexOf("pictogram") != -1) {
          object.visible = true;
        }
        if (this.showAvatars == true && object.settings.keywords.indexOf("avatar") != -1) {
          object.visible = true;
        }
        */
        object.visible = true;

        if ( !this.showImages && object.type == "image" ) {
          object.visible = false;
        }
        if ( !this.showVideos && object.type == "video" ) {
          object.visible = false;
        }
        if ( !this.showAudios && object.type == "audio" ) {
          object.visible = false;
        }

        // Ordnerstruktur
        /*if (this.showFolders && this.currentFolder != "" && (object.foldername != this.currentFolder)) {
          object.visible = false;
        }
        else if (this.showFolders && this.currentFolder == "" && (object.foldername)) {
          object.visible = false;
        }*/

        if (this.showFolders && this.currentFolder != object.folder) {
          object.visible = false;
        }

        if (object.visible == true) {
          this.filter.push(object);
        }
      }
    }

    // falls letztes Item aus einem Ordner...
    /*
    if (this.showFolders && this.currentFolder != "" && this.filter.length <= 0) {
      this.currentFolder = "";
      this.filterObjects();
    }
    */

  }

  openFolder(folder: Folder) {
    console.log("openFolder, folder=", folder);
    this.currentFolder = folder.id;
    this.filterObjects();
  }

  closeFolder(currentFolder: number = -1) {
    this.currentFolder = this.getFolder(currentFolder).parent;
    this.filterObjects();
  }

  getFolder(id: number) {
    for (let folder of this.folders) {
      if (folder.id == id) {
        return folder;
      }
    }
    return null;
  }

  async createObject() {

    this.global.dismissToast();

    const modal = await this.modalCtrl.create({
      component: MediaComponent,
      componentProps: {
        'object': null,
        'currentFolder': this.currentFolder,
        'nameList': this.nameList.filter(x => x.id === String(this.currentFolder)),
        'types': this.types
      },
      backdropDismiss: false,
      cssClass: 'media-modal'
    });

    modal.onDidDismiss().then((data) => {
      console.log(this.selected);
      if (data && data.data && data.data.mediaid) {
        this.selected = data.data.mediaid;
        this.filename = data.data.filename;
        this.selectedType = data.data.type;
        this.selectedName = data.data.name;
      }

      this.initComponent();
    });

    return await modal.present();
  }

  async selectObject(object: any) {
    this.selected = object.id;
    this.filename = object.filename;
    this.selectedType = object.type;
    this.selectedName = object.name;
  }

  async saveObject() {
    this.modalCtrl.dismiss({
      'selected': this.selected,
      'filename': this.filename,
      'mediatype': this.selectedType,
      'medianame': this.selectedName
    });
  }

  async deleteSource() {
    this.modalCtrl.dismiss({
      'selected': -1,
      'filename': "",
      'mediatype': "",
      'medianame': ""
    });
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  async openOptions() {
    const popover = await this.modalCtrl.create({
      component: MediaoptionsComponent,
      componentProps: {
        'users': this.showUsers,
        'locations': this.showLocations,
        'sequences': this.showSequences,
        'pictograms': this.showPictograms,
        'avatars': this.showAvatars,
        'owners': this.showOwners,
        'folders': this.showFolders,
        'showImages': this.showImages,
        'showVideos': this.showVideos,
        'showAudios': this.showAudios,
        'types': this.types
      },
      backdropDismiss: false
    });

    popover.onDidDismiss().then((data) => {
      if (data && data.data) {
        this.showUsers = data.data.users;
        this.showLocations = data.data.locations;
        this.showSequences = data.data.sequences;
        this.showPictograms = data.data.pictograms;
        this.showAvatars = data.data.avatars;
        this.showOwners = data.data.owners;
        this.showFolders = data.data.folders;
        this.showImages = data.data.showImages;
        this.showVideos = data.data.showVideos;
        this.showAudios = data.data.showAudios;

        let filterSettings: any = {
          "users": this.showUsers,
          "locations": this.showLocations,
          "sequences": this.showSequences,
          "pictograms": this.showPictograms,
          "avatars": this.showAvatars,
          "owners": this.showOwners,
          "folders": this.showFolders,
          "showImages": this.showImages,
          "showVideos": this.showVideos,
          "showAudios": this.showAudios
        }
        this.dataSrv.setItem("mediaFilter_" + this.tags, filterSettings);
      }

      this.filterObjects();
    });

    return await popover.present();
  }

  ngOnInit() { }

}

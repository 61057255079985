import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import tinycolor from 'tinycolor2'; 

export interface IonColor{
  key: string;
  value: string;
  friendlyName: string;
};

@Injectable({
  providedIn: 'root'
})
export class ColorService {

  
private ionPrefix : string = ".ion-color-";

  public colorList: IonColor[] = [
    { key: "blue", value: "#007EBD", friendlyName: "Blau" },
    { key: "darkblue", value: "#004466", friendlyName: "Dunkel-Blau" },
    { key: "violett", value: "#7030A0", friendlyName: "Violett" },
    { key: "hellflieder", value: "#E8A5E3", friendlyName: "Hell-Flieder" },
    { key: "pink",  value: "#ED57BE", friendlyName: "Pink" },
    { key: "tiefrot", value: "#B62C22", friendlyName: "Tief-Rot" },
    { key: "leichtzimt", value: "#FF836E", friendlyName: "Leicht-Zimt" },
    { key: "orange",    value: "#ED7D31", friendlyName: "Orange" },
    { key: "senf", value: "#BF9000", friendlyName: "Senf" },
    { key: "goldgelb",    value: "#FFC000", friendlyName: "Gold-Gelb" },
		{ key: "hellgelb",    value: "#F8E895", friendlyName: "Hell-Gelb" },
    { key: "gruen", value: "#548235", friendlyName: "Grün" },
    { key: "pastellgruen", value: "#A9D18E", friendlyName: "Pastell-Grün" },
    { key: "braun",    value: "#936844", friendlyName: "Braun" },
    { key: "kobaltblau", value: "#2F5597", friendlyName: "Kobalt-Blau" },
    { key: "hellblau", value: "#8FAADC", friendlyName: "Hell-Blau" },
    { key: "lichtblau",     value: "#5CCDE3", friendlyName: "Licht-Blau" },
    { key: "dunkelgrau", value: "#3B3838", friendlyName: "Dunkel-Grau" },
    { key: "grau", value: "#7F7F7F", friendlyName: "Grau" },
    { key: "weiss",     value: "#FFFFFF", friendlyName: "Weiß" }
];

  constructor(@Inject(DOCUMENT) private document: Document,) {
     this.colorList.forEach( c => this.addIonColor(c.key, c.value));
  }

  public getColorValue(colorKey:string):string{
    let idx = this.colorList.map( c=>c.key).indexOf(colorKey);
    return idx == -1 ? undefined : this.colorList[idx].value;
  }

  public getColorByValue(colorValue: string): IonColor {
    colorValue = colorValue.toUpperCase();
    let idx = this.colorList.map( c=>c.value).indexOf(colorValue);
    //return idx == -1 ? undefined : this.colorList[idx];
    return idx == -1 ? this.colorList[0] : this.colorList[idx];
  }

  public addIonColor(name:string, baseColor:string){
    const namePattern = /^[a-zA-Z][\-_0-9A-Za-z]+$/;
    
    if(!namePattern.test(name)){
      throw new Error(`Invalid color name: ${name} should match /^[a-zA-Z][\-_0-9A-Za-z]$/` );
      return;
    }
    let color = new tinycolor(baseColor);
    
    if ( !color.isValid() ) {
      throw new Error(`Invalid color value: ${baseColor}`)
      return;
    }
    let hex = color.toString('hex6');
    let rgb = color.toRgb();
    let contrast = tinycolor( color.getBrightness() > 150 ? "#222" : "#eee" );
    let contrastRgb = contrast.toRgb();
    
    let css = `${this.ionPrefix+name} {
      --ion-color-base: ${hex};
      --ion-color-base-rgb: ${rgb.r},${rgb.g},${rgb.b};
      --ion-color-contrast: ${contrast.toString('hex6')};
      --ion-color-contrast-rgb: ${contrastRgb.r},${contrastRgb.g},${contrastRgb.b};
      --ion-color-shade: ${color.darken().toString('hex6')};
      --ion-color-tint: ${color.lighten().toString('hex6')};
    }
    `
    //console.log(css);
    
    var docStyle = this.document.createElement('style');
    docStyle.type = 'text/css';
    docStyle.innerHTML = css;
    this.document.getElementsByTagName('head')[0].appendChild(docStyle);
  }
}

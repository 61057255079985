import { element } from 'protractor';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NavParams, ModalController, AlertController, IonList, IonCol, IonContent, IonCheckbox } from '@ionic/angular';
import { ApiService } from './../../services/api.service';
import { DataService } from '../../services/data.service';
import { GlobalService } from './../../services/global.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';

import { DragulaService } from 'ng2-dragula';

import { Folder } from '../../media/media.page';

import { LoadingBoxService } from 'src/app/services/loading-box.service';

@Component({
  selector: 'app-media-drag-drop',
  templateUrl: './media-drag-drop.component.html',
  styleUrls: ['./media-drag-drop.component.scss'],
})
export class MediaDragDropComponent implements OnInit {

  @ViewChild('listColLeft', { static: false }) listLeft: IonCol;
  @ViewChild('listColRight', { static: false }) listRight: IonCol;

  initialized: boolean = false;

  mediaUrl: string = environment.mediaUrl;

  types: string[] = [];

  folders: Folder[] = [];
  //currentFolder: number = -1; // root

  currentFolderLeft: number = -1;
  currentFolderRight: number = -1;

  folderList: any[] = [];

  // Media
  media: any[] = [];
  nameList: any[] = [];

  dragContainer = "DRAGULA_CONTAINER";
  dragSubs: Subscription = new Subscription();

  dragAccepted: boolean = false;
  dragDuplicated: boolean = false;
  dragAllowed: boolean = true;

  constructor(
    private navParams: NavParams,
    public apiSrv: ApiService,
    public dataSrv: DataService,
    public global: GlobalService,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    public translate: TranslateService,
    private dragulaService: DragulaService,
    public loadingBoxService: LoadingBoxService) {
    
    let folders = navParams.get("folders");
    if (folders) {
      this.folders = folders;
    }
    //console.log("this.folders:", this.folders);

    let media = navParams.get("media");
    if (media) {
      this.media = media;
    }
    //console.log("this.media:", this.media);

    let current = navParams.get("currentFolder");
    if (current) {
      this.currentFolderLeft = -1; // all time default root folder
      this.currentFolderRight = current;
    }
    console.log("this.currentFolderLeft:", this.currentFolderLeft);
    console.log("this.currentFolderRight:", this.currentFolderRight);
    
    let types = navParams.get("types");
    if (types) {
      this.types = types;
    }
    console.log("this.types:", this.types);

    this.dragSubs.add(dragulaService.drag(this.dragContainer)
      .subscribe(({ el }) => {

        //console.log("drag :: this.dragContainer=", this.dragContainer);
        //console.log("drag :: el=", el);

        
        //console.log("drag el:", el, ", this.currentFolderRight: ", this.currentFolderRight, ", this.currentFolderLeft: ", this.currentFolderLeft);

        /*
        let type: string = el.getAttribute('class');
        let isFolder: boolean = type.indexOf('folder') >= 0 ? true : false;
        let targetId: number = type.indexOf('left') >= 0 ? this.currentFolderRight : this.currentFolderLeft;
        let sourceId: number = Number(el.getAttribute('id'));

        let duplicated: boolean = this.checkForDuplicate(sourceId, targetId, isFolder);

        let allowed: boolean = true;
        if (isFolder) {
          allowed = this.draggingAllowed(sourceId, targetId);
          //console.log("isFolder", isFolder, ", this.draggingAllowed(", sourceId, ",", targetId, "):", allowed);
        }
        
        if (this.currentFolderLeft == this.currentFolderRight) {
          this.global.presentToast("Keine Aktion im gleichen Ordner. Wählen Sie auf einer Seite einen anderen Ordner.", 2000);
        }
        else if (!allowed) {
          this.global.presentToast("Ordner kann nicht in sich selbst verschoben werden.", 2000);
        }
        else */

        /*
        if (this.dragAccepted && this.dragDuplicated) {
          this.global.presentToast("Der Name des Ordners/der Datei ist im Zielordner bereits vorhanden!", 2000);
        }
        */

      })
    );

    this.dragSubs.add(dragulaService.dragend(this.dragContainer)
      .subscribe(({ el }) => {
        //console.log("dragend el:", el, ", class=", el.getAttribute('class'));
        //console.log("dragend this.currentFolderLeft : ", this.currentFolderLeft, ", this.currentFolderRight: ", this.currentFolderRight);

        if (!this.dragAccepted) {
          console.log("dragend this.dragAccepted:", this.dragAccepted);
          return false;
        }

        if (this.currentFolderLeft == this.currentFolderRight) {
          this.global.presentToast("Keine Aktion im gleichen Ordner. Wählen Sie auf einer Seite einen anderen Ordner.", 2000);
        }
        else if (!this.dragAllowed) {
          this.global.presentToast("Ordner kann nicht in sich selbst verschoben werden.", 2000);
        }
        else if (this.dragDuplicated) {
          this.global.presentToast("Der Name des Ordners/der Datei ist im Zielordner bereits vorhanden!", 2000);
        }

      })
    );
  
    this.dragSubs.add(dragulaService.drop(this.dragContainer)
      .subscribe(({ el }) => {

        this.global.dismissToast();

        //console.log("dragulaService.drop :: el:", el, ", this.currentFolderRight: ", this.currentFolderRight, ", this.currentFolderLeft: ", this.currentFolderLeft);

        let type: string = el.getAttribute('class');
        let side: string = type.indexOf('left') >= 0 ? 'left' : 'right';
        //console.log("   drop type:", type.split(" ")[0], " ", type.split(" ")[1]);
        //console.log("   drop side:", side);

        let selectedItems: any[] = [];
        for (let folder of this.folders) {
          if (folder.isChecked) {
            console.log("isChecked: ", folder.name);
            //if (folder.id != Number(el.getAttribute('id'))) {
            folder.parent = side == 'left' ? this.currentFolderRight : this.currentFolderLeft;
            selectedItems.push(folder);
            //}
          }
        }

        for (let item of this.media) {
          if (item.isChecked) {
            console.log("isChecked: ", item.name);
            //if (item.id != Number(el.getAttribute('id'))) {
            item.folder = side == 'left' ? this.currentFolderRight : this.currentFolderLeft;
            selectedItems.push(item);
            //}
          }
        }
        console.log("selectedItems=", selectedItems);

        if (selectedItems.length <= 0 && type.indexOf('folder') >= 0) {
          let folder: Folder = this.getFolder(Number(el.getAttribute('id')));
          //console.log("   folder: ", folder);
          folder.parent = side == 'left' ? this.currentFolderRight : this.currentFolderLeft;
          this.saveFolder(folder, true);
        }
        else if (selectedItems.length <= 0) {
          let media: any = this.getMedia(Number(el.getAttribute('id')));
          //console.log("   media: ", media);
          media.folder = side == 'left' ? this.currentFolderRight : this.currentFolderLeft;
          this.saveMedium(media);
        }

        if (selectedItems.length > 0) {
          this.saveItems(selectedItems);
        }

        el.classList.remove(side);
        el.classList.add(side == 'left' ? 'right' : 'left');
      })
    );
  
    /*
    this.dragSubs.add(dragulaService.over(this.dragContainer)
      .subscribe(({ el, container }) => {
        console.log("over el:", el, ", container=", container);
        console.log("el: ", el.getAttribute('class'));  
      })
    );
    */
    
    /*
    this.dragSubs.add(dragulaService.out(this.dragContainer)
      .subscribe(({ el, container }) => {
        console.log("out el:", el, ", container=", container);
        console.log("el: ", el.getAttribute('class'));
      })
    );
    */
    

    this.dragulaService.createGroup(this.dragContainer, {
      /*
      moves: (el, source, handle, sibling) => {
        console.log("dragulaService ::  moves, el: ", el, ", source: ", source, ", handle: ", handle, ", sibling: ", sibling);
        return true;
      },
      */
      accepts: (el, target, source, sibling) => {
        //console.info("!!! accepts, el: ", el, ", target: ", target, ", source: ", source, ", sibling: ", sibling, ", this.currentFolderLeft : ", this.currentFolderLeft, ", this.currentFolderRight: ", this.currentFolderRight);

        this.dragAccepted = true;
        if (!target || !source || (target == source)) {
          this.dragAccepted = false;
          //console.warn("!!! accepts, (!target || !source || (target == source) => this.dragAccepted:", this.dragAccepted)
          return false;
        }
        //console.log("!!! accepts, check more...");

        let type: string = el.getAttribute('class');
        let isFolder: boolean = type.indexOf('folder') >= 0 ? true : false;
        let side: string = type.indexOf('left') >= 0 ? 'left' : 'right';
        //console.info("!!! accepts, el type:", type, ", side:", side);
        let targetId: number = type.indexOf('left') >= 0 ? this.currentFolderRight : this.currentFolderLeft;
        let sourceId: number = Number(el.getAttribute('id'));

        this.dragAllowed = true;

        if (isFolder) {
          this.dragAllowed = this.draggingAllowed(sourceId, targetId);
        }

        this.dragDuplicated = this.checkForDuplicate(sourceId, targetId, isFolder);

        //console.log("!!! accepts, this.dragAllowed:", this.dragAllowed, ", this.dragDuplicated", this.dragDuplicated);
        
        //console.log("!!! accepts, (this.currentFolderLeft == this.currentFolderRight):", (this.currentFolderLeft == this.currentFolderRight));
        
        if ((this.currentFolderLeft == this.currentFolderRight) || !this.dragAllowed || this.dragDuplicated) {
          //this.dragAccepted = false;
          return false;
        }
        else {
          //this.dragAccepted = true;
          return true;
        }
      },
      removeOnSpill: false,
      revertOnSpill: true
    });
    
    //this.init();

  }

  ngOnInit() { }
  
  ionViewDidEnter() {
    this.init();
  }

  async init() {

    console.log("media-drag-drop.component, init ++++++++++++++++++++++++++++++++++++++++++++++");

    await this.loadingBoxService.dismiss();

    this.folderList = [];
    for (let folder of this.folders) {
      if (folder.name && folder.name.length > 0) {
        this.folderList.push({ id: folder.parent, name: folder.name });
      }
    }
    //console.log("this.folderList: ", this.folderList);

    this.nameList = [];
    for (let item of this.media) {
      if (item.name && item.name.length > 0) {
        this.nameList.push({ id: item.folder, name: item.name, folder: item.folder });
      }
    }
    //console.log("this.nameList: ", this.nameList);
    
    this.initialized = true;

  }

  ngOnDestroy() {
    this.global.dismissToast();
    this.dragSubs.unsubscribe();
    this.dragulaService.destroy(this.dragContainer);
  }

  async saveItems(items: any[], firstCall: boolean = true) {
    
    let item: any = items.shift();
    
    console.log("media-drag-drop.component, saveItems ++++++++++++++++++++++++++++++++++++++++++++++ item=", item);
    
    if (item["parent"] != null) {
      console.log("saveItems folder");
      await this.saveFolder(item, false, firstCall);
    }
    else {
      console.log("saveItems medium");
      await this.saveMedium(item, false, firstCall);
    }

    if (items.length > 0) {
      this.saveItems(items, false);
    }
    else {
      await this.loadingBoxService.dismiss();
      this.init();
    }
  }

  async saveMedium(item: any, doInit: boolean = true, showLoading: boolean = true) {

    if (showLoading) {
      await this.loadingBoxService.present('SAVE-DATA', await this.translate.instant('COMMON.SAVE-DATA'));
    }

    let token = await this.dataSrv.getItem("token");
    let response = await this.apiSrv.doPost('media/modify?id=' + item.id, { "token": token }, item);
    let json = JSON.parse(JSON.stringify(response));
    
    console.log("media-drag-drop.component, saveMedium, json:", json);

    if (json.error.code == 200) {
      response = await this.apiSrv.doGet('media', { "token": token }, {});
      this.media = JSON.parse(JSON.stringify(response));
    }

    //await this.loadingBoxService.dismiss('SAVE-DATA');
    await this.loadingBoxService.dismiss();

    if (doInit) {
      this.init();
    }

  }

  async saveFolder(folder: Folder, doInit: boolean = true, showLoading: boolean = true) {
    
    if (showLoading) {
      await this.loadingBoxService.present('SAVE-DATA', await this.translate.instant('COMMON.SAVE-DATA'));
    }

    let token = await this.dataSrv.getItem("token");
    let response = await this.apiSrv.doPost('folders/modify?id=' + folder.id, { "token": token }, folder);
    let json = JSON.parse(JSON.stringify(response));
    
    console.log("media-drag-drop.component, saveFolder, json:", json);

    if (json.error.code == 200) {
      response = await this.apiSrv.doGet('folders', { "token": token }, {});
      this.folders = JSON.parse(JSON.stringify(response));
    }

    //await this.loadingBoxService.dismiss('SAVE-DATA');
    await this.loadingBoxService.dismiss();

    if (doInit) {
      this.init();
    }

  }

  async close() {

    this.loadingBoxService.dismiss();

    this.modalCtrl.dismiss({
      'folders': this.folders,
      'media': this.media
    });

  }

  
  openFolderLeft(folder: Folder, event: Event) {

    // GEHT NICHT, in der Konstellation ist das immer eine CheckBox!!! :-(
    console.log("openFolderLeft, clickStopper? ", event, event.target["localName"] == "ion-checkbox");
    if (event.target && event.target["localName"] == "ion-checkbox") {
      event.stopPropagation();
      return;
    }

    this.global.dismissToast();
    console.log("openFolder, folder=", folder);
    this.currentFolderLeft = folder.id;
    console.log("openFolder, this.currentFolderLeft=", this.currentFolderLeft);
    if (this.listLeft) {
      this.listLeft["el"].scrollTo(0, 0);
    }
  }

  openFolderRight(folder: Folder, event: Event) {

    // GEHT NICHT, in der Konstellation ist das immer eine CheckBox!!! :-(
    console.log("openFolderLeft, clickStopper? ", event, event.target["localName"] == "ion-checkbox");
    if (event.target && event.target["localName"] == "ion-checkbox") {
      event.stopPropagation();
      return;
    }

    this.global.dismissToast();
    console.log("openFolder, folder=", folder);
    this.currentFolderRight = folder.id;
    console.log("openFolder, this.currentFolderRight=", this.currentFolderRight);
    if (this.listRight) {
      this.listRight["el"].scrollTo(0, 0);
    }
  }

  closeFolderLeft(folder: Folder) {
    this.currentFolderLeft = folder == null ? -1 : folder.parent;
    console.log("closeFolder, this.currentFolderLeft=", this.currentFolderLeft);
  }
  closeFolderRight(folder: Folder) {
    this.currentFolderRight = folder == null ? -1 : folder.parent;
    console.log("closeFolder, this.currentFolderRight=", this.currentFolderRight);
  }

  getFullPath(id: number) {
    let path: string = "";
    let folder: Folder = this.getFolder(id);
    if (folder) {
      path = folder.name;
      while (folder && folder.parent != -1) {
        folder = this.getFolder(folder.parent);
        path = folder.name + " / " + path;
      }
    }
    return "./ " + path;
  }

  getFolder(id: number) {
    for (let folder of this.folders) {
      if (folder.id == id) {
        return folder;
      }
    }
    return null;
  }

  getFolderNameById(id: number) {
    for (let folder of this.folders) {
      if (folder.id == id) {
        return folder.name;
      }
    }
    return 'root';
  }

  getMedia(id: number) {
    for (let item of this.media) {
      if (item.id == id) {
        return item;
      }
    }
    return null;
  }

  checkForDuplicate(sourceId: number, targetId: number, isFolder: boolean = false): boolean {

    //console.log("checkForDuplicate :: sourceId: ", sourceId, ", targetId: ", targetId, ", isFolder: ", isFolder);

    let isAllowed: boolean = false;
    let list: any[];

    if (isFolder) {
      let sourceFolder: Folder = this.getFolder(sourceId);
      list = this.folderList.filter(x => x.id === String(targetId))
      //console.log("list=", list);
      if (list.find(x => x.name === sourceFolder.name)) {
        isAllowed = true;
      }
    }
    else {
      let sourceItem: any = this.getMedia(sourceId);
      list = this.nameList.filter(x => x.folder === String(targetId))
      //console.log("list=", list);
      if (list.find(x => x.name === sourceItem.name)) {
        isAllowed = true;
      }
    }

    return isAllowed;
  }

  draggingAllowed(sourceId: number, targetId: number): boolean {
    
    //console.log("draggingAllowed, sourceId: ", sourceId, ", targetId: ", targetId);

    let targetFolder: Folder = this.getFolder(targetId);
    let sourceFolder: Folder = this.getFolder(sourceId);

    //console.log("     targetFolder: ", targetFolder, ", sourceFolder: ", sourceFolder);

    if (sourceFolder && sourceFolder.id == targetId) {
      // Ordner kann nicht ins sich selbst gezogen werden
      return false;
    }
    else if (targetFolder && sourceFolder) {

      //console.log("     sourceFolder.parent: ", sourceFolder.parent);
      //console.log("     sourceFolder.parent != targetId: ", (sourceFolder.parent != targetId));

      do {
        if (sourceFolder.id == targetFolder.parent) {
          return false;
        }
      } while (targetFolder = this.getFolder(targetFolder.parent))

    }

    return true;
  }

  /*
  async saveFolders() {
    let token = await this.dataSrv.getItem("token");
    let response: any;
    for (let folder of this.folders) {
      response = await this.apiSrv.doPost('folders/modify?id=' + folder.id, { "token": token }, folder);
      let json = JSON.parse(JSON.stringify(response));
      console.log("saveFolders, json:", json);
      if (json.error.code == 200) {
      }
    }
  }
  */

  
  /*
  async saveMedia() {
    let token = await this.dataSrv.getItem("token");
    let response: any;
    for (let item of this.media) {
      response = await this.apiSrv.doPost('media/modify?id=' + item.id, { "token": token }, item);
      let json = JSON.parse(JSON.stringify(response));
      console.log("saveMedia, json:", json);
      if (json.error.code == 200) {
      }
    }
  }
  */
}
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { IonColor, ColorService } from '../../services/color.service';

/*
Example: https://stackblitz.com/edit/ionic-4-color-list?file=src%2Fapp%2Fcolor-popover%2Fcolor-picker-popover.page.ts
https://github.com/bgrins/TinyColor
*/

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.page.html',
  styleUrls: ['./color-picker.page.scss'],
})
export class ColorPickerPage implements OnInit {

  //@ViewChild(List) list: List;  

    //@ViewChild("theList", { static: false }) private list: ElementRef;
  
  private currentColor: IonColor;

  constructor(public colorService: ColorService, 
      private navParams:NavParams, 
      private popCtrl:PopoverController) { }

  ngOnInit() {
    this.currentColor = this.navParams.get('color');
  }

  closePopover(){
    this.popCtrl.getTop().then( p => p.dismiss(this.currentColor) ) 
  }


  selectColor(idx:number){
    this.currentColor = this.colorService.colorList[idx];
    this.closePopover();
      // console.log(`Selected: ${idx} from ColorPickerPopoverPage`)
  }

}

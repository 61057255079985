import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { File } from '@ionic-native/file/ngx';

@Injectable()
export class MediaService {

  constructor(
    private httpClient: HttpClient,
    private file: File
  ) { }

  getMedia(mediaUrl: string): Observable<Blob> {
    return this.httpClient.get(mediaUrl, { responseType: 'blob' as 'blob' });
  }

  convertFileToBlob(filePath: string) {
    return new Promise((resolve, reject) => {
      let fileName: string = "";
      let fileExtension: string = "";
      this.file.resolveLocalFilesystemUrl(filePath)
        .then(fileEntry => {
          let { name, nativeURL } = fileEntry;
          // get the path..
          let path: string = nativeURL.substring(0, nativeURL.lastIndexOf("/"));
          fileName = name;
          // if you already know the file extension, just assign it to           
          // variable below
          fileExtension = fileName.match(/\.[A-z0-9]+$/i)[0].slice(1);
          // we are provided the name, so now read the file into a buffer
          return this.file.readAsArrayBuffer(path, name);
        })
        .then(buffer => {
          // get the buffer and make a blob to be saved
          let medBlob: Blob = new Blob([buffer], {
            type: `audio/${fileExtension}`
          });

          // pass back blob and the name of the file for saving
          // into fire base
          resolve({ blob: medBlob, blobName: name });
        })
        .catch(e => reject(e));
    });
  }



}
import { ImageEditorComponent } from './../image-editor/image-editor.component';
import { ImageEditor2Component } from './../image-editor2/image-editor2.component';
//import { Observable } from 'rxjs';
import { Component, OnInit, ViewChild, ElementRef, SecurityContext } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
//import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { MediaCapture, MediaFile, CaptureError, CaptureImageOptions, CaptureVideoOptions } from '@ionic-native/media-capture/ngx';
import { Platform } from '@ionic/angular';

import { ApiService } from '../../services/api.service';
import { DataService } from '../../services/data.service';
import { GlobalService } from '../../services/global.service';

import { environment } from '../../../environments/environment';

import { WebView } from '@ionic-native/ionic-webview/ngx';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { MediaService } from '../../services/media.service';

import { AlertController } from '@ionic/angular';

import { IonicSelectableComponent } from 'ionic-selectable';

import { TranslateService } from '@ngx-translate/core';

import { File } from '@ionic-native/file/ngx';

// https://ionicframework.com/docs/native/media
//import { Media, MediaObject, MEDIA_STATUS } from '@ionic-native/media/ngx';
//import { File } from '@ionic-native/file/ngx';

import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';

import { LoadingBoxService } from 'src/app/services/loading-box.service';


@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss'],
})
export class MediaComponent implements OnInit {

  me: any = {};
  objectOwner: boolean = false;

  types: string[] = [];

  showImages: boolean = true;
  showVideos: boolean = true;
  showAudios: boolean = false;

  edit: boolean = false;

  mediaUrl: string = environment.mediaUrl;

  medium: any = {
    name: "",
    description: "",
    folder: "",
    filename: "",
    owner: -1,
    type: "",
    settings: {
      tags: ""
    }
  }

  // names
  nameList: any[];
  nameIsUnique: boolean = true;

  // html helper flag
  mediumLoaded: boolean = false;

  // file dialog
  fileList: FileList;

  // media capture
  mediaFile: MediaFile;
  isMediaLoading: boolean;

  imageData: any;
  videoData: any;
  iOSvideoData: any;

  disableSave: boolean = false;

  fileChooserAccept: string = "";

  constructor(
    private navParams: NavParams,
    public modalCtrl: ModalController,
    public apiSrv: ApiService,
    public dataSrv: DataService,
    public global: GlobalService,
    public alertCtrl: AlertController,
    //private camera: Camera,
    private mediaCapture: MediaCapture,
    public platform: Platform,
    public mediaService: MediaService,
    //public toastController: ToastController,
    private webview: WebView,
    public sanitizer: DomSanitizer,
    private file: File,
    //public loadingController: LoadingController,
    public translate: TranslateService,
    private androidPermissions: AndroidPermissions,
    public loadingBoxService: LoadingBoxService
  ) {

    let object = navParams.get("object");
    if (object) {
      this.edit = true;
      this.medium = object;
      console.log("media.component, this.medium=", this.medium);
    }

    console.log("this.medium.folder=", this.medium.folder);
    if (this.edit == false) {
      this.medium.folder = navParams.get("currentFolder");
    }

    this.nameList = [];
    this.nameIsUnique = true;
    let names = navParams.get("nameList");
    if (names) {
      this.nameList = names;
    }
    //console.log("this.nameList=", this.nameList);

    if (this.medium.type == "video") {
      this.videoData = this.mediaUrl + this.medium.filename;
    }
    else if (this.medium.type == "image") {
      this.imageData = this.mediaUrl + this.medium.filename;
    }

    let types = navParams.get("types");
    if (types) {
      this.types = types;
    }
    //console.log("this.types:", this.types);

    if (this.platform.is('ios')) {
      //this.mediumLoaded = true;
    }

  }

  videoEventLog(evt: any) {
    console.log("!!!!!!!!!!!!!!!!!!! videoEventLog ", evt.type, " !!!!!!!!!!!!!!!");
    this.mediumLoaded = true;
  }

  canplaythrough() {
    this.mediumLoaded = true;
    console.log("!!!!!!!!!!!!!!!!!!! CANPLAYTROUGH !!!!!!!!!!!!!!!");
  }

  ngOnInit() { }

  ionViewDidEnter() {
    this.init();
  }

  async init() {

    this.me = await this.dataSrv.getItem("me");

    if (this.edit == false) {
      this.objectOwner = true;
      this.medium.owner = this.me.id;
    } else {
      if (this.medium.isEditable) {
        this.objectOwner = true;
      }
    }

    if (this.types.indexOf("image") < 0) {
      this.showImages = false;
    }
    if (this.types.indexOf("video") < 0) {
      this.showVideos = false;
    }
    if (this.types.indexOf("audio") < 0) {
      this.showAudios = false;
    }
    // all times
    this.showAudios = false;

    // https://www.w3schools.com/tags/att_input_accept.asp
    // https://www.iana.org/assignments/media-types/media-types.xhtml

    let fileChooserAcceptParts: string[] = [];
    //if ( this.showImages ) fileChooserAcceptParts.push("image/*"); 
    //if ( this.showVideos ) fileChooserAcceptParts.push("video/*");
    if (this.showImages) fileChooserAcceptParts.push("image/x-png,image/gif,image/jpeg");
    if (this.showVideos) fileChooserAcceptParts.push("video/mp4,video/x-m4v,video/quicktime");
    //if ( this.showAudios ) fileChooserAcceptParts.push("audio/mpeg,audio/ogg");
    //fileChooserAcceptParts.push( this.showImages || this.showVideos ? "capture=camera" : "" ); 
    //fileChooserAcceptParts.push( this.showVideos ? "capture=microphone" : "" ); 

    this.fileChooserAccept = fileChooserAcceptParts.join(",");

    console.log("this.fileChooserAccept: ", this.fileChooserAccept);

  }

  checkName(name: string) {
    //console.log("media.components: ", name, ", nameList: ", this.nameList, ", ", (this.nameList.find(x => x.name === name)));
    this.nameIsUnique = !(this.nameList.find(x => x.name.toLowerCase() === name.toLowerCase()));
  }

  reset(type: string) {

    // reset saved medium all time
    this.medium.filename = "";

    this.imageData = null;
    this.videoData = null;
    this.iOSvideoData = null;

    switch (type) {
      case "filedialog": {
        this.fileList = null;
        document.getElementById("fileChooser")["value"] = "";
      } break;
      case "mediacapture": {
        this.mediaFile = null;
        this.isMediaLoading = false;
      } break;
      default:
    }
  }

  get saveConditionsFulfilled(): boolean {

    let conditionsFulfilled: boolean = true;

    let hasName: boolean = (this.medium.name != '');

    let hasImageOrVideoData: boolean = this.imageData || this.videoData;

    if (!hasName || !hasImageOrVideoData) {
      conditionsFulfilled = false;
    }

    return conditionsFulfilled;

  }

  fileChange(event) {

    // reset
    this.reset("mediacapture");

    this.fileList = event.target.files;
    console.log("fileChange:", this.fileList[0]);

    /*
    let msg: string = "filelist:\n";
    let foo: any;
    for (let x in this.fileList) {
      foo = this.fileList[x];
      for (let y in foo) {
        msg += y + "=" + foo[y] + "\n";
      }
    }
    console.log(msg);
    */

    if (this.fileList[0].type.indexOf("image") >= 0) {
      this.global.convertFileToDataURLviaFileReaderBlob(this.fileList[0]).subscribe(
        base64 => {
          this.imageData = base64;
        }
      );
    }
    else if (this.fileList[0].type.indexOf("video") >= 0) {
      console.log("this.fileList[0]=", this.fileList[0]);

      if (this.platform.is('ios')) {

        this.global.getBase64ByFileOrBlob(this.fileList[0]).then((base64: any) => {

          console.log("getFile, base64: ", base64);

          // base64 zum speichern
          this.iOSvideoData = base64;

          let blob: Blob = this.global.b64ToBlob(base64.split(",")[1], "video/quicktime");
          let fd: FormDataEntryValue = this.global.blobToFile(blob, "cached_video");
          //console.log("fd=", fd);

          this.file.writeFile(this.file.cacheDirectory, "cached_video.mov", fd, { replace: true }).then((value) => {
            let fixedUrl: string = this.webview.convertFileSrc(this.file.cacheDirectory + "cached_video.mov");
            let safeResourceUrl: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fixedUrl);
            let resourceUrl: string = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, safeResourceUrl);
            console.log("fixedUrl=", fixedUrl, ", safeResourceUrl=", safeResourceUrl, ", resourceUrl=", resourceUrl);

            this.videoData = safeResourceUrl;

          }).catch((e) => {
            console.error("local file choose process (ios), write file error, e=", e);
          });

        });

      }
      else {
        this.global.convertFileToDataURLviaFileReaderBlob(this.fileList[0]).subscribe(
          base64 => {
            this.videoData = base64;
          }
        );
      }
    }
    else {
      console.log("media.component, selected file type ('" + this.fileList[0].type + "') not supported!");
    }

  }

  takeMedia(type: string) {

    // reset
    this.reset("filedialog");

    if (this.platform.is('android')) {
      this.androidPermissions.requestPermissions([this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE, this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE]);
    }

    if (type == 'picture') {
      let options: CaptureImageOptions = { limit: 1 };
      this.mediaCapture.captureImage(options)
        .then(
          (data: MediaFile[]) => {
            this.mediaFile = data[0];
            /*
            //let safeResourceUrl: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.mediaFile.fullPath);
            this.mediaToShowURL = this.webview.convertFileSrc(this.mediaFile.fullPath);
            //let resourceUrl: string = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, safeResourceUrl);
            //this.mediaToShowURL = this.webview.convertFileSrc(resourceUrl);
            */

            console.log("this.mediaFile.fullPath=", this.mediaFile.fullPath);

            if (this.platform.is('ios')) {
              let fixedUrl: string = this.webview.convertFileSrc(this.mediaFile.fullPath);
              let safeResourceUrl: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fixedUrl);
              let resourceUrl: string = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, safeResourceUrl);
              console.log("fixedUrl=", fixedUrl, ", safeResourceUrl=", safeResourceUrl, ", resourceUrl=", resourceUrl);

              this.global.convertFileToDataURLviaFileReader(resourceUrl).subscribe(
                base64 => {
                  console.log("base64=", base64);
                  this.imageData = base64;
                }
              );
            }
            else {
              this.imageData = this.webview.convertFileSrc(this.mediaFile.fullPath);
            }

            this.isMediaLoading = true;

          },
          (err: CaptureError) => console.error(err)
        );
    }
    else if (type == 'video') {

      let options: CaptureVideoOptions = { limit: 1 }

      this.mediaCapture.captureVideo(options)
        .then(
          (data: MediaFile[]) => {

            this.mediaFile = data[0];
            console.log("this.mediaFile=", this.mediaFile);

            /*
            let safeResourceUrl: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.mediaFile.fullPath);
            //this.mediaToShowURL = this.webview.convertFileSrc(this.mediaFile.fullPath);
            let resourceUrl: string = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, safeResourceUrl);
            this.mediaToShowURL = this.webview.convertFileSrc(resourceUrl);
            */

            if (this.platform.is('ios')) {

              /*
              let fileName: string = this.mediaFile.name;
              let dir: string[] = this.mediaFile['localURL'].split('/');
              dir.pop();
              let fromDir: string = dir.join('/');
              let toDir: string = this.file.tempDirectory;
     
              console.log("fileName=", fileName, ", dir=", dir, ", fromDir=", fromDir, ", toDir=", toDir);
     
              this.file.copyFile(fromDir, fileName, toDir, fileName).then(res => {
                console.log("copyFile, res=", res);
                let url: string = res.nativeURL.replace(/^file:\/\//, '');
                console.log("copyFile, url=", url);
                this.videoData = url;
              });
              */

              //let fixedUrl: string = this.webview.convertFileSrc(this.file.tempDirectory + this.mediaFile.name);
              let fixedUrl: string = this.webview.convertFileSrc(this.mediaFile.fullPath);
              let safeResourceUrl: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fixedUrl);
              //let resourceUrl: string = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, safeResourceUrl);
              console.log("fixedUrl=", fixedUrl, ", safeResourceUrl=", safeResourceUrl);
              this.videoData = safeResourceUrl;

              this.global.convertFileToDataURLviaFileReader(fixedUrl).subscribe(
                base64 => {
                  this.iOSvideoData = base64;
                }
              );

            }
            else {
              this.videoData = this.webview.convertFileSrc(this.mediaFile.fullPath);
            }

            this.isMediaLoading = true;

          },
          (err: CaptureError) => console.error(err)
        );

    }
    else {
      console.log("takeMedia, type" + type + "not found");
    }
  }

  async saveObjectPre() {
    this.disableSave = true;
    setTimeout(() => this.saveObject(), 200);
  }

  async saveObject() {

    await this.loadingBoxService.present('SAVE-DATA', this.translate.instant('COMMON.SAVE-DATA'));

    if (this.imageData) {

      let ext: string = "jpg";
      if (this.imageData.indexOf("data") == 0) {
        ext = this.global.base64MimeType(this.imageData).split("/")[1];
      }
      else {
        ext = this.imageData.split(".").pop();
      }

      let formData: FormData = new FormData();
      let filename: string = "new_" + this.global.generateUUID() + "." + ext;

      const url = this.imageData;

      let res = await fetch(url);
      let blob = await res.blob();

      formData.append('uploadFile', blob, filename);

      await this.saveFormData(formData);

    }
    else if (this.videoData) {

      console.log("iosVideoData=", this.iOSvideoData);

      let ext: string = "mp4";
      if (this.videoData.indexOf && this.videoData.indexOf("data") == 0) {
        ext = this.global.base64MimeType(this.videoData).split("/")[1];
      }
      else {
        if (this.platform.is('ios') && this.mediaFile) {
          ext = this.mediaFile.name.split(".").pop().toLowerCase();
        }
        else if (this.platform.is('ios') && this.iOSvideoData && this.iOSvideoData.indexOf && this.iOSvideoData.indexOf("data") == 0) {
          ext = this.global.base64MimeType(this.iOSvideoData).split("/")[1];
        }
        else {
          ext = this.videoData.split(".").pop();
        }
      }

      if (ext == "quicktime") {
        ext = "mov";
      }

      let formData: FormData = new FormData();
      let filename: string = "new_" + this.global.generateUUID() + "." + ext;

      const url = (this.platform.is('ios') && this.iOSvideoData) ? this.iOSvideoData : this.videoData;

      let res = await fetch(url);
      let blob = await res.blob();

      console.log("filename=", filename, ", blob=", blob);

      formData.append('uploadFile', blob, filename);

      await this.saveFormData(formData);
    }
    /*
        // Image, Video oder Audio aus dem FileDialog...
        if (this.fileList && this.fileList.length > 0) {
    
          console.log("media.component, saveObject (fileList), fileList[0]=" + this.fileList[0]);
    
          //let file: File = this.fileList[0];
          let formData: FormData = new FormData();
    
          //formData.append('uploadFile', file, file.name);
          formData.append('uploadFile', this.fileList[0], this.fileList[0].name);
    
          await this.saveFormData(formData);
    
        }
        // Aufnamne Image oder Video
        else if (this.mediaFile) {
    
          //let safeResourceUrl: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.mediaFile.fullPath);
    
          this.mediaService.getMedia(this.webview.convertFileSrc(this.mediaFile.fullPath))
            .subscribe(
              async data => {
    
                
                //let msg: string = "mediaService response:\n";
                //for (let x in data) {
                //  msg += x + "=" + data[x] + "\n";
                //}
                //alert(msg);
    
                let formData: FormData = new FormData();
    
                formData.append('uploadFile', data, this.mediaFile.name);
    
                await this.saveFormData(formData);
    
              }, error => {
                this.disableSave = false;
                console.error(error);
              });
    
        }
        else if (this.mediaToShowURL && this.mediaToShowURL.indexOf("data") == 0) {
    
          let formData: FormData = new FormData();
          let filename: string = "cropped_" + this.global.generateUUID() + ".jpg";
    
          const url = this.mediaToShowURL;
    
          let res = await fetch(url);
          let blob = await res.blob();
    
          formData.append('uploadFile', blob, filename);
          
          await this.saveFormData(formData);
    
          
          //await fetch(url)
          //  .then(res => res.blob())
          //  .then(blob => {
          //    formData.append('uploadFile', blob, filename);
          //    this.saveFormData(formData);
          //    //URL.revokeObjectURL(blob);
          //  });
    
        }
          */
    else {
      alert("media.components, check 'saveObject' ...");

      if (this.edit == false) {
        this.createMedia();
      } else {
        this.modifyMedia();
      }
    }

    await this.global.dismissToast();
    //await this.loadingBoxService.dismiss('SAVE-DATA');
    await this.loadingBoxService.dismissAllLoaders();
  }

  async saveFormData(formData: FormData) {

    if (formData) {
      let token = await this.dataSrv.getItem("token");
      let response = await this.apiSrv.doUpload('media/upload', { "token": token }, {}, formData);
      let json = JSON.parse(JSON.stringify(response));

      console.log("media.component, saveFormData, json response=", json);

      this.medium.filename = json.filename;

      let ext: string = this.medium.filename.split(".").pop().toLowerCase();

      switch (ext) {
        case "jpg":
        case "jpeg":
        case "png":
        case "gif":
          this.medium.type = "image";
          break;

        case "mp3":
          this.medium.type = "audio";
          break;

        case "mp4":
        case "mov":
          this.medium.type = "video";
          break;

        default:
          console.log("media.component, saveFormData, der media type mit der Extension ", ext, " wird nicht unterstützt!");
      }
      //console.log("this.medium.type=", this.medium.type);

      if (this.edit == false) {
        this.createMedia();
      }
      else {
        this.modifyMedia();
      }
    }
  }

  async createMedia() {

    let token = await this.dataSrv.getItem("token");
    console.log("createMedia, this.medium=", this.medium);
    let response = await this.apiSrv.doPost('media/create', { "token": token }, this.medium);
    let json = JSON.parse(JSON.stringify(response));
    if (json.error.code == 200) {
      console.log("media.component, createMedia, json=", json);
      this.modalCtrl.dismiss({
        error: 200,
        filename: this.medium.filename,
        mediaid: json.id,
        type: this.medium.type,
        name: this.medium.name
      });
    }
    else {
      this.disableSave = false;
    }

    await this.global.dismissToast();
    //await this.loadingBoxService.dismiss('SAVE-DATA');
    await this.loadingBoxService.dismissAllLoaders();
  }

  async modifyMedia() {

    let token = await this.dataSrv.getItem("token");
    let response = await this.apiSrv.doPost('media/modify?id=' + this.medium.id, { "token": token }, this.medium);
    let json = JSON.parse(JSON.stringify(response));
    if (json.error.code == 200) {
      this.modalCtrl.dismiss({
        error: 200,
        filename: this.medium.filename
      });
    }
    else {
      this.disableSave = false;
    }

    await this.global.dismissToast();
    //await this.loadingBoxService.dismiss('SAVE-DATA');
    await this.loadingBoxService.dismissAllLoaders();

  }

  async deleteObject() {
    let message = await this.translate.instant('COMMON.DELETE-IMAGE', { name: this.medium.name });
    if (this.medium.type == "video") {
      message = await this.translate.instant('COMMON.DELETE-VIDEO', { name: this.medium.name });
    }
    const alert = await this.alertCtrl.create({
      subHeader: await this.translate.instant('COMMON.ATTENTION'),
      message: message,
      buttons: [
        {
          text: await this.translate.instant('COMMON.NO'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: await this.translate.instant('COMMON.YES'),
          handler: () => {
            this.doDelete();
          }
        }
      ]
    });

    await alert.present();
  }

  async doDelete() {
    let token = await this.dataSrv.getItem("token");
    let response = await this.apiSrv.doPost('media/delete?id=' + this.medium.id, { "token": token }, this.medium);
    let json = JSON.parse(JSON.stringify(response));

    if (json.code == 200) {
      this.modalCtrl.dismiss({
        error: 200
      });
    }
  }

  async closeModal() {

    await this.global.dismissToast();
    //await this.loadingBoxService.dismiss('SAVE-DATA');
    await this.loadingBoxService.dismissAllLoaders();

    this.modalCtrl.dismiss();
  }

  async openEditImage() {
    const modal = await this.modalCtrl.create({
      component: ImageEditorComponent,
      componentProps: {
        //'image64': this.mediaToShowURL
        'image64': this.imageData
      },
      backdropDismiss: false,
      cssClass: 'image-editor-modal'
    });

    modal.onDidDismiss().then((data) => {
      if (data && data.data && data.data.image64) {

        this.reset("filedialog");
        this.reset("mediacapture");

        //this.mediaToShowURL = data.data.image64;
        this.imageData = data.data.image64;
      }

    });

    return await modal.present();
  }

  async openEditImage2() {
    const modal = await this.modalCtrl.create({
      component: ImageEditor2Component,
      componentProps: {
        //'image64': this.mediaToShowURL
        'image64': this.imageData
      },
      backdropDismiss: false,
      cssClass: 'image-editor-modal'
    });

    modal.onDidDismiss().then((data) => {
      if (data && data.data && data.data.image64) {

        this.reset("filedialog");
        this.reset("mediacapture");

        //this.mediaToShowURL = data.data.image64;
        this.imageData = data.data.image64;
      }

    });

    return await modal.present();
  }

  /* ****************************************************************************************
   
      C O P Y  M E D I A
   
     ************************************************************************************** */

  async presentAlertPromptCopyMedia(mediaToCopy: any) {
    const alert = await this.alertCtrl.create({
      cssClass: 'alert-class',
      header: await this.translate.instant('MEDIA.COMPONENT.COPY.HEADLINE'),
      inputs: [
        {
          name: 'name',
          type: 'text',
          value: name,
          placeholder: await this.translate.instant('MEDIA.COMPONENT.COPY.PREFIX')
        }
      ],
      buttons: [
        {
          text: await this.translate.instant('COMMON.CANCEL'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Abbruch "Medium kopieren"');
          }
        }, {
          text: await this.translate.instant('COMMON.ASSUME'),
          handler: (data) => {
            console.log('Medium "' + mediaToCopy.name + '" (mit id=' + mediaToCopy.id + ') als neues Medium mit dem Name "' + data.name + '" kopieren...');
            this.copyMedia(mediaToCopy, data.name);
          }
        }
      ]
    });

    alert.onDidDismiss().then((data) => {

      this.global.dismissToast();
      //this.loadingBoxService.dismiss('SAVE-DATA');
      this.loadingBoxService.dismissAllLoaders();

      this.modalCtrl.dismiss();
    });

    await alert.present();
  }

  async copyMedia(mediaToCopy: any, newName: string) {

    console.log("copyMedia, mediaToCopy=", mediaToCopy);

    let newMedia: any = {
      name: newName,
      description: mediaToCopy.description,
      filename: mediaToCopy.filename,
      owner: this.me.id,
      type: mediaToCopy.type,
      settings: mediaToCopy.settings,
      folder: mediaToCopy.folder
    }

    let token = await this.dataSrv.getItem("token");
    let response = await this.apiSrv.doPost('media/copy', { "token": token }, newMedia);
    let json = JSON.parse(JSON.stringify(response));

    console.log("media.component, copyMedia, json=", json);

    if (json.error == null || json.error.code != 200) {
      this.global.presentToast('Medium konnte nicht kopiert werden!');
      await this.dataSrv.setItem("newMediaId", -1);
    }
    else { // json.error.code == 200
      this.global.presentToast('Medium erfolgreich kopiert!');
      await this.dataSrv.setItem("newMediaId", json.id);
    }

  }

}

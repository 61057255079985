import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { IonButton, ModalController, PopoverController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { AlertController } from '@ionic/angular';

import { ApiService } from '../../services/api.service';
import { DataService } from '../../services/data.service';
import { GlobalService } from '../../services/global.service';

import { environment } from '../../../environments/environment';

import { FilechooserComponent } from '../filechooser/filechooser.component';

import { TranslateService } from '@ngx-translate/core';

import { IonColor, ColorService } from '../../services/color.service';
import { ColorPickerPage } from '../../popovers/color-picker/color-picker.page';

import { WebView } from '@ionic-native/ionic-webview/ngx';

import { TextToSpeechService } from '../../services/tts.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {

  me: any = {};
  objectOwner: boolean = false;
  edit = false;

  fileList: FileList;

  previewURL: string = "home"; //"./home";
  previewURLSecure: SafeResourceUrl = null;
  previewURLFileSrc: any = null;

  user: any = {
    username: "",
    firstname: "",
    lastname: "",
    phone: "",
    password: "",
    password_repeat: "",
    email: "",
    image: -1,
    maxrole: "USER",
    owner: -1,
    advisorname: "",
    advisorimage: -1,
    advisorphone: "",
    settings: {
      "buttons": "normal",
      "text": true,
      "fontsize": "normal",
      "contrast": "normal",
      "rooms": true,
      "reminder": true,
      "progress": true,
      "audio": true,
      "rating": true,
      "assist": "1",
      "clickbg": "#004466",
      "clickcolor": "#ffffff",
      "titlebg": "#007EBD",
      "titlecolor": "#ffffff",
      "calladvisor": true,
      "avatardelay": 180000 // 1000 * 60 * 3 = 180000 = 3 Minuten
    }
  }

  colorClickBG: IonColor;
  colorClickText: IonColor;
  colorTitleBG: IonColor;
  colorTitleText: IonColor;

  mediaUrl: string = environment.mediaUrl;

  showContent: boolean = false;

  tutorSpecialRights: boolean = false;

  section: string = "personal"; // personal | settings | advisor

  tutors: any[] = [];
  tutorInput: string = "";
  tutorsFiltered: any[] = [];

  // names
  usernameSaved: string;
  usernameList: string[];
  usernameIsUnique: boolean = true;

  disableFooterButtons: boolean = false;

  // @ASH: Für was war das Flag gedacht? Wird im Code nicht verwendet!
  //togglePassword : boolean = false;

  constructor(
    navParams: NavParams,
    public modalCtrl: ModalController,
    public apiSrv: ApiService,
    public dataSrv: DataService,
    public global: GlobalService,
    public alertCtrl: AlertController,
    public translate: TranslateService,
    private sanitizer: DomSanitizer,
    public colorService:ColorService,
    public pop:PopoverController,
    private webview: WebView,
    private ttsService: TextToSpeechService
  ) {

    this.usernameSaved = "";

    let object = navParams.get("object");
    if (object) {
      this.edit = true;
      this.user = object;
      this.usernameSaved = this.user.username;
    }

    // falls es noch Benutzer ohne gibt...
    if (!this.user.settings) {
      this.user["settings"] = {
        "buttons": "normal",
        "text": true,
        "fontsize": "normal",
        "contrast": "normal",
        "rooms": true,
        "reminder": true,
        "progress": true,
        "audio": true,
        "rating": true,
        "assist": "1",
        "clickbg": "#004466",
        "clickcolor": "#ffffff",
        "titlebg": "#007EBD",
        "titlecolor": "#ffffff",
        "calladvisor": true,
        "avatardelay": 180000
      };
    }

    if (this.user.settings['avatardelay'] == null) {
      this.user.settings['avatardelay'] = 180000;
    }

    /*
    if (this.user.settings.mediafilter) {
      delete this.user.settings.mediafilter;
    }
    */

    console.log("user.components, constructor, this.user=", this.user);

    if (!this.user.settings.clickbg) {
      this.user.settings.clickbg = "#004466";
    }
    if (!this.user.settings.clickcolor) {
      this.user.settings.clickcolor = "#FFFFFF";
    }
    if (!this.user.settings.titlebg) {
      this.user.settings.titlebg = "#007EBD";
    }
    if (!this.user.settings.titlecolor) {
      this.user.settings.titlecolor = "#FFFFFF";
    }

    let tutors = navParams.get("tutors");
    if (tutors) {
      this.tutors = tutors;
    }

    this.usernameList = [];
    this.usernameIsUnique = true;
    let names = navParams.get("usernameList");
    if (names) {
      this.usernameList = names;
    }

    console.log("users.component: this.usernameList=", this.usernameList);

    //this.init();
  }

  ionViewDidEnter() {
    // TODO: Notwendig an der Stelle, nur hier werden doch die Daten geändert und sind dann direkt im "lokalen" >> me << drin...
    this.init();
  }

  async init() {
    // reset preview user
    this.dataSrv.setItem("previewUser", {});

    this.me = await this.dataSrv.getItem("me");

    if (this.edit == false) {
      this.objectOwner = true;
      this.user.owner = this.me.id;
    }
    else {
      if (this.user.owner == this.me.id) {
        this.objectOwner = true;
      }
      if (this.me.maxrole == 'ADMIN' || this.me.maxrole == 'SYSTEM') {
        this.objectOwner = true;
      }
      // Nach Abst. mit Jan Schierreich am Fr., 05.02.2021 darf jeder Tutor jeden Beschäftigten "bearbeiten".
      //if (this.me.maxrole == 'TUTOR' && this.me.id == this.user.id) {
      if ( this.me.maxrole == 'TUTOR' && ( this.me.id == this.user.id || this.user.maxrole == 'USER' ) ) {
        this.objectOwner = true;
      }
    }

    // TODO: Mit der Änderung der Rechte, dass jeder TUTOR alle USER bearbeiten darf, ist vermutlich dieses Flag obsole. Prüfen!
    this.tutorSpecialRights = (this.me.maxrole == 'TUTOR' && this.me.id == this.user.id);

    this.colorClickBG = this.colorService.getColorByValue( this.user.settings.clickbg );
    this.colorClickText = this.colorService.getColorByValue( this.user.settings.clickcolor );
    this.colorTitleBG = this.colorService.getColorByValue( this.user.settings.titlebg );
    this.colorTitleText = this.colorService.getColorByValue(this.user.settings.titlecolor);

    this.reloadPreview();

    // beim Start immer alle Tutoren...
    this.tutorsFiltered = this.tutors;
    this.filterTutors();this.filterTutors();

    this.showContent = true;
  }

  checkUsername(name: string) {
    //console.log("users.components: ", name, ", usernameList: ", this.usernameList, ", ", !(this.usernameList.some(e => e === name)));
    this.usernameIsUnique = !(this.usernameList.some(e => e === name));
    if ( this.usernameSaved == name ) {
      this.usernameIsUnique = true;
    }
    this.disableFooterButtons = !this.usernameIsUnique;
  }

  fileChange(event) {
    this.fileList = event.target.files;
    console.log(this.fileList)
  }

  async completeObject() {
    if (this.edit == false) {
      this.createObject(true);
    } else {
      this.modifyObject(true);
    }
  }

  async saveObject() {
    if (this.edit == false) {
      this.createObject(false);
    } else {
      this.modifyObject(false);
    }
  }

  toastCallback() {
    this.disableFooterButtons = false;
  }

  async createObject(doDissmiss: boolean) {
    
    this.disableFooterButtons = true;

    let token = await this.dataSrv.getItem("token");

    let response = await this.apiSrv.doPost('users/create', { "token": token }, this.user);
    let json = JSON.parse(JSON.stringify(response));
    console.log(response);
    if (json.error && json.error.code == 200) {
      this.dataSrv.setItem("previewUser", {});
      this.global.presentToast(this.translate.instant('USERS.COMPONENT.SAVE-SUCCESS', { name: this.user.username }), 3000, this.toastCallback.bind(this));
      if (doDissmiss == true) {
        this.modalCtrl.dismiss({
          error: 200,
          object: this.user
        });
      }
      // ab jetzt modifiy
      if (json.userid) {
        this.user.id = json.userid;
      }
      this.edit = true;
    } else {
      this.global.presentToast(this.translate.instant('USERS.COMPONENT.SAVE-ERROR', { name: this.user.username }), 5000, this.toastCallback.bind(this));
    }
  }

  async modifyObject(doDissmiss: boolean) {
    
    this.disableFooterButtons = true;

    let token = await this.dataSrv.getItem("token");

    console.log("modifyObject: this.user=", this.user);
    
    let response = await this.apiSrv.doPost('users/modify?id=' + this.user.id, { "token": token }, this.user);
    let json = JSON.parse(JSON.stringify(response));
    console.log(response);

    if (json.error && json.error.code == 200) {
      this.dataSrv.setItem("previewUser", {});
      this.global.presentToast(this.translate.instant('USERS.COMPONENT.MODIFY-SUCCESS', { name: this.user.username }), 3000, this.toastCallback.bind(this));
      if (doDissmiss == true) {
        this.modalCtrl.dismiss({
          error: 200,
          object: this.user
        });
      }
    } else {
      this.global.presentToast(this.translate.instant('USERS.COMPONENT.MODIFY-ERROR', { name: this.user.username }), 5000, this.toastCallback.bind(this));
    }
  }

  async deleteObject() {
    const alert = await this.alertCtrl.create({
      subHeader: await this.translate.instant('COMMON.ATTENTION'),
      message: await this.translate.instant('USERS.COMPONENT.DELETE-USER-QUERY', { username: this.user.username }),
      buttons: [
        {
          text: await this.translate.instant('COMMON.NO'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: await this.translate.instant('COMMON.YES'),
          handler: () => {
            this.doDelete();
          }
        }
      ]
    });

    await alert.present();
  }

  async doDelete() {
    let token = await this.dataSrv.getItem("token");
    let response = await this.apiSrv.doPost('users/delete?id=' + this.user.id, { "token": token }, this.user);
    let json = JSON.parse(JSON.stringify(response));

    if (json.code == 200) {
      this.dataSrv.setItem("previewUser", {});
      this.modalCtrl.dismiss({
        error: 200
      });
    }
  }

  async selectMedia() {
    if (this.objectOwner == false && !this.tutorSpecialRights) {
      return;
    }

    const modal = await this.modalCtrl.create({
      component: FilechooserComponent,
      componentProps: {
        'image': this.user.image,
        'types': ['image'],
        'tags': 'user'
      },
      cssClass: 'filechooser-modal',
      backdropDismiss: false
    });

    modal.onDidDismiss().then((data) => {
      if (data && data.data && data.data.selected) {
        this.user.image = data.data.selected;
        this.user.filename = data.data.filename;
      }
    });

    return await modal.present();
  }

  async selectAdvisor() {
    if (this.objectOwner == false) {
      return;
    }

    const modal = await this.modalCtrl.create({
      component: FilechooserComponent,
      componentProps: {
        'image': this.user.advisorimage,
        'types': ['image'],
        'tags': 'user'
      },
      cssClass: 'filechooser-modal',
      backdropDismiss: false
    });

    modal.onDidDismiss().then((data) => {
      if (data && data.data && data.data.selected) {
        this.user.advisorimage = data.data.selected;
        this.user.advisorfilename = data.data.filename;
      }
    });

    return await modal.present();
  }

  async closeModal(demand: boolean = false) {
    if (demand) {
      const alert = await this.alertCtrl.create({
        subHeader: await this.translate.instant('COMMON.ATTENTION'),
        message: await this.translate.instant('USERS.COMPONENT.CLOSE-MESSAGE'),
        buttons: [
          {
            text: await this.translate.instant('COMMON.NO'),
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              console.log('Confirm Cancel: blah');
            }
          }, {
            text: await this.translate.instant('COMMON.YES'),
            handler: () => {
              this.dataSrv.setItem("previewUser", {});
              this.modalCtrl.dismiss();
            }
          }
        ]
      });

      await alert.present();
    }
    else {
      this.modalCtrl.dismiss();
    }
  }

  showPasswordText: boolean = false;
  passwordToggleIcon: string = "eye";
  togglePassword() {
    this.showPasswordText = !this.showPasswordText;
    this.passwordToggleIcon = (this.showPasswordText) ? "eye-off" : "eye";
  }

  passwordChanged(event: Event) {

  }

  ngOnInit() { }

  async speakText(speakText: string) {
    console.log("speakText, text=", speakText);
    this.ttsService.speak(speakText);
  }

  stopTTS() {
    if (this.ttsService) {
      this.ttsService.cancel();
    }
  }

  reloadPreview() {

    this.stopTTS();
    return;

    console.log("RELOAD PREVIEW...");

    this.dataSrv.setItem("previewUser", this.user);

    console.log("+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++");
    console.log('document.location', document.location.href);
    console.log('document.location.href', document.location.href.substring(0,document.location.href.lastIndexOf("/")+1));
    console.log('window.location.href', window.location.href.substring(0,window.location.href.lastIndexOf("/")+1));

    this.previewURL = document.location.href.substring(0,document.location.href.lastIndexOf("/")+1) + "home"; // "list";
    //this.previewURL = this.previewURL.replace("http", "https");

    console.log('this.previewURL', this.previewURL);

    //this.previewURL = this.previewURL + "?" + new Date().getTime();

    console.log('this.previewURL', this.previewURL);

    this.previewURLSecure = this.sanitizer.bypassSecurityTrustResourceUrl(this.previewURL);

    console.log('this.previewURLSecure', this.previewURLSecure);

    //this.previewURLFileSrc = this.webview.convertFileSrc(this.previewURL);
    //console.log('this.previewURLFileSrc', this.previewURLFileSrc);
  }

  onloadPreview(event: any) {
    /*
    if (event && event.currentTarget) {
      console.log(event.currentTarget.contentDocument);
      console.log(event.currentTarget.contentDocument.body);
      console.log(event.currentTarget.contentDocument.documentElement);
      console.log(event.currentTarget.contentDocument.documentElement.lastChild);
      event.currentTarget.contentDocument.body.style.scale = 1.0;
    }
    */
  }

  async pickColorClick(ev: Event, col: IonColor) {

    // TODO: Umbau von "id" auf 2. Parameter
    
    console.log(ev.currentTarget);
    console.log(ev.currentTarget["id"]);
    console.log("color: ", col, ", ", col.key, ", ", col.value);

    let _col: IonColor;
    let _colCurrent: String = ev.currentTarget["id"];
    switch (_colCurrent) {
      case "btn_clickbg":
        _col = this.colorClickBG;
        break;
      case "btn_clickcolor":
        _col = this.colorClickText;
        break;
      case "btn_titlebg": 
        _col = this.colorTitleBG; 
        break;
      case "btn_titlecolor": 
        _col = this.colorTitleText; 
        break;
      default:
        _col = this.colorService.colorList[0];
    }

    //_col = col;

    let colorPickerPopover = await this.pop.create(
      {
      component: ColorPickerPage,
      event: ev,
      componentProps: {
        color: _col
      }
    });

    colorPickerPopover.onDidDismiss()
      .then((data) => {
          const x = data['data']; // Here's returned value from popover
          console.log("colorPickerPopover, result: ", x);

          switch (_colCurrent) {
            case "btn_clickbg":
              this.colorClickBG = x || this.colorClickBG;
              this.user.settings.clickbg = this.colorClickBG.value;
              break;
            case "btn_clickcolor":
              this.colorClickText = x || this.colorClickText;
              this.user.settings.clickcolor = this.colorClickText.value;
              break;
            case "btn_titlebg": 
              this.colorTitleBG = x || this.colorTitleBG;
              this.user.settings.titlebg = this.colorTitleBG.value;
              break;
            case "btn_titlecolor": 
              this.colorTitleText = x || this.colorTitleText;
              this.user.settings.titlecolor = this.colorTitleText.value;
              break;
          }
          
          this.reloadPreview();
        });
    
    return await colorPickerPopover.present();
  }

  filterTutors() {
    
    this.tutorsFiltered = [];

    let temp: any[] = [];

    if (this.tutorInput == "") {
      temp = this.tutors;
    }
    else {
      let searchVal: string = this.tutorInput.toLowerCase();
      for (let object of this.tutors) {
          if (
            object.username
              .toLowerCase()
              .indexOf(searchVal) != -1 ||
            object.firstname
              .toLowerCase()
              .indexOf(searchVal) != -1 ||
            object.lastname
              .toLowerCase()
              .indexOf(searchVal) != -1
          ) {
            temp.push(object);
          }
      }
    }

    for (let object of temp) {
      //console.log("tutorsFiltered: ", object);

      if (this.user.advisorid == object.id) {
        object.asigned = true;
      }

      this.tutorsFiltered.push(object);
    }
  }

  asignTutor(tutor: any) {
    if (!tutor.asigned) return;
    for (let object of this.tutorsFiltered) {
      object.asigned = false;
    }
    tutor.asigned = true;

    this.user.advisorid = tutor.id;

    /*
    for (let object of this.tutorsFiltered) {
      console.log(object.username, ", asigned=", object.asigned);
    }
    */
  }

}

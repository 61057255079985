import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { TextToSpeech } from '@ionic-native/text-to-speech/ngx';

@Injectable({
  providedIn: 'root'
})
export class TextToSpeechService {

  public locale: string = "de-DE";
  private utterance: SpeechSynthesisUtterance = null;
  private synthesis: any = null;
  private rate: number = 1; // android
  //public playing: boolean = false;

  constructor(public platform: Platform, public tts: TextToSpeech) {

    if (!this.platform.is("cordova")) {
      if (SpeechSynthesisUtterance !== undefined && window.speechSynthesis !== undefined) {
        this.utterance = new SpeechSynthesisUtterance();
        this.synthesis = window.speechSynthesis;
      }
    }

    if (this.platform.is('ios')) {
      this.rate = 1.5; // ios
    }

  }

  public speak(text: string) {

    console.log("TextToSpeachService:speak, text=", text);

    if (this.platform.is("cordova")) {
      let options = {
        "text": text,
        "locale": this.locale,
        "rate": this.rate
      }
      this.tts.speak(options)
        .then(() => console.log('TTS success'))
        .catch((reason: any) => console.log(reason));
    }
    else if (this.utterance && this.synthesis) {

      this.utterance.text = text;
      this.utterance.lang = this.locale;

      if (this.speaking) {
        this.cancel();
      }

      this.synthesis.speak(this.utterance);

    }

  }

  public get speaking() {
    if (!this.platform.is("cordova")) {
      return this.synthesis.speaking;
    }
    else
      return false;
  }

  public cancel() {
    if (!this.platform.is("cordova")) {
      this.synthesis.cancel();
    }
    else {
      //FIXME: seems not working!
      //this.tts.stop();
      this.tts.speak("")
        .then(() => console.log('TTS cancled success'))
        .catch((reason: any) => console.log(reason));
    }
  }


  public pause() {
    if (!this.platform.is("cordova")) {
      this.synthesis.pause();
    }
    else {
      // FIXME: sofern es ein Pause im Corodva Plugin gibt...
      this.tts.stop();
      console.log("tts.service.ts: kein pause oder suspend im corova plugin!")
    }
  }

  public resume() {
    if (!this.platform.is("cordova")) {
      this.synthesis.resume();
    }
    else {
      // FIXME: sofern es ein Resume im Corodva Plugin gibt...
      console.log("tts.service.ts: kein resume im corova plugin!")
    }
  }

}

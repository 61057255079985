import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-owneroptions',
  templateUrl: './owneroptions.component.html',
  styleUrls: ['./owneroptions.component.scss'],
})
export class OwneroptionsComponent implements OnInit {

  owners: boolean = false;
  tutors: boolean = false;
  users: boolean = false;

  name: string = "...";

  constructor(
    navParams: NavParams,
    public modalCtrl: ModalController,
    public translate: TranslateService
  ) { }

  saveOptions() {
    this.modalCtrl.dismiss({
      'owners': this.owners,
      'tutors': this.tutors,
      'users': this.users
    })
  }
  closeModal() {
    this.modalCtrl.dismiss();
  }

  ngOnInit() { }

  stateChange(type, event) {
    if (type == "tutors" && this.tutors)
      this.users = false;
    else if (type == "users" && this.users)
      this.tutors = false;
  }

}

import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpRequest, HttpParams, HttpResponse } from '@angular/common/http';

import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public authToken: string = null;

  constructor(
    private httpCtrl: HttpClient,
    public dataSrv: DataService
  ) {
  }

  async doGet(uri: string, headers: any, params: any) {
    let token = await this.dataSrv.getItem("token");
    let config: any = {};
    config["x-access-token"] = 'BbZJjyoXAdr8BUZuiKKARWimKfrSmQ6fv8kZ7OFfc';
    config["Accept"] = 'application/json';
    config["Content-Type"] = 'application/x-www-form-urlencoded';
    if (this.authToken) {
      config["token"] = this.authToken;
    }
    if (token) {
      config["token"] = token;
    }

    Object.keys(headers).forEach(function (key) {
      config[key] = headers[key];
    })

    let _headers: HttpHeaders = new HttpHeaders(config);
    let httpOptions = {
      headers: _headers
    };

    uri += "?";
    Object.keys(params).forEach(function (key) {
      uri += key + "=" + params[key] + "&";
    })
    uri += "date=" + new Date().getTime();

    console.log(environment.bridgeUrl);

    try {
      let response = await this.httpCtrl.get(environment.bridgeUrl + uri, httpOptions).toPromise();
      return response;
    } catch (ex) {
      return { "error": 404 };
    }
  }

  async doPost(uri: string, headers: any, params: any) {
    let token = await this.dataSrv.getItem("token");
    let config: any = {};
    config["x-access-token"] = 'BbZJjyoXAdr8BUZuiKKARWimKfrSmQ6fv8kZ7OFfc';
    config["Accept"] = 'application/json';
    config["Content-Type"] = 'application/json';
    if (this.authToken) {
      config["token"] = this.authToken;
    }
    if (token) {
      config["token"] = token;
    }

    Object.keys(headers).forEach(function (key) {
      config[key] = headers[key];
    })

    let _headers: HttpHeaders = new HttpHeaders(config);
    let httpOptions = {
      headers: _headers
    };

    console.log(environment.bridgeUrl);

    /* uri += "?";
    Object.keys(params).forEach(function(key) {
      uri += key + "=" + params[key] + "&";
    })
    uri += "date=" + new Date().getTime(); */
    return await this.httpCtrl.post(environment.bridgeUrl + uri, params, httpOptions).toPromise();
  }

  async doUpload(uri: string, headers: any, params: any, form: FormData) {
    let config: any = {};
    config["x-access-token"] = 'BbZJjyoXAdr8BUZuiKKARWimKfrSmQ6fv8kZ7OFfc';
    config["Accept"] = 'application/json';

    Object.keys(headers).forEach(function (key) {
      config[key] = headers[key];
    })

    let _headers: HttpHeaders = new HttpHeaders(config);
    let httpOptions = {
      headers: _headers
    };

    uri += "?";
    Object.keys(params).forEach(function (key) {
      uri += key + "=" + params[key] + "&";
    })
    uri += "date=" + new Date().getTime();

    return await this.httpCtrl.post(environment.bridgeUrl + uri, form, httpOptions).toPromise();
  }

  /* async doUpload( uri : string, headers : any, params : any, form : FormData ) {
    let config : any = {};
        config["Accept"] = 'image/png';
    
    Object.keys(headers).forEach(function(key) {
      config[key] = headers[key];
    })
    
    let _headers : HttpHeaders = new HttpHeaders(config);
    let httpOptions = {
      headers : _headers
    };

    uri += "?";
    Object.keys(params).forEach(function(key) {
      uri += key + "=" + params[key] + "&";
    })
    uri += "date=" + new Date().getTime();
    // environment.bridgeUrl + uri
    let body = "egal";
    return await this.httpCtrl.put("http://192.168.2.162:8000/YoloVorlage/gedeck005.png", body).toPromise();
  } */
}

import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-response-box',
  templateUrl: './response-box.component.html',
  styleUrls: ['./response-box.component.scss'],
})
export class ResponseBoxComponent implements OnInit {

  message: string = "";

  constructor(
    navParams: NavParams,
    public modalCtrl: ModalController,
    public translate: TranslateService
  ) { }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  ngOnInit() { }

}

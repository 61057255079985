import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  prefix: string = "commonapp";

  constructor(
    protected Storage: Storage
  ) {
  }

  initDataService() {
    //  https://medium.com/@filipvitas/indexeddb-with-promises-and-async-await-3d047dddd313
  }

  async getItem(key: any) {
    let item: any = null;
    let fullkey: string = this.prefix + "_" + key;
    await this.Storage.get(fullkey).then((val) => {
      try {
        item = JSON.parse(val);
      } catch (e) {
        item = null;
      }
    });

    return item;
  }

  async setItem(key: any, object: any) {
    let fullkey: string = this.prefix + "_" + key;
    this.Storage.set(fullkey, JSON.stringify(object));
  }

  async clearItem(key: any) {
    let fullkey: string = this.prefix + "_" + key;
    this.Storage.remove(fullkey);
  }

  bytesToSize(bytes: number) {
    let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    let zaehler: number = Math.log(bytes);
    let nenner: number = Math.log(1024);
    let bruch: number = Math.floor(zaehler / nenner);
    let i: number = parseInt((bruch + ""), 10);
    let nenner2: number = Math.pow(1024, bruch);
    let round: number = Math.round(bytes / nenner2);
    return round + ' ' + sizes[i];
  };

  async getSize() {
    let allValues: string = "";
    let sizeNumber: number = 0;

    await this.Storage.forEach((v, k, i) => {
      allValues += v + k + i;
    }).then(() => {
      sizeNumber = allValues.length;
    });

    return this.bytesToSize(sizeNumber);
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { GlobalService } from './../../services/global.service';
import { TranslateService } from '@ngx-translate/core';

import { CropperComponent } from 'angular-cropperjs';

// angular-cropperjs
// https://github.com/matheusdavidson/angular-cropperjs
// https://github.com/fengyuanchen/cropperjs
// https://github.com/fengyuanchen/cropperjs#features
// https://ionicacademy.com/ionic-image-crop-cropperjs/
// Example (old v0.1.5):
// https://github.com/chr1soscl/imageEditorCropperJS
// https://fengyuanchen.github.io/cropperjs/

@Component({
  selector: 'app-image-editor2',
  templateUrl: './image-editor2.component.html',
  styleUrls: ['./image-editor2.component.scss'],
})
export class ImageEditor2Component implements OnInit {

   @ViewChild('angularCropper', { static: false }) public angularCropper: CropperComponent;
  
  cropperOptions: any;
  croppedImage: any = null;
  myImage: any = null;
  scaleValX: number = 1;
  scaleValY: number = 1;
  cropperRotation: number = 0;

  image64Data: any;  

  undoList: any[]; 
  changed: boolean = false;

  imageEdited: boolean = false;

  cropperVisible: boolean = true;

  constructor(
    private navParams: NavParams,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    public translate: TranslateService,
    public global: GlobalService) {
   
    this.undoList = [];
    this.imageEdited = false;
    this.cropperRotation = 0;

    // https://github.com/fengyuanchen/cropperjs#features
    this.cropperOptions = {
      viewMode: 1,
      dragMode: 'crop',
      initialAspectRatio: (4/3),
      aspectRatio: NaN,
      responsive: true,
      restore: true,
      checkCrossOrigin: true,
      checkOrientation: true,
      modal: true,
      guides: true,
      center: true,
      highlight: true,
      background: true,
      autoCrop: true,
      autoCropArea: 1.0, // default: 0.8
      movable: true,
      rotatable: true,
      scalable: true,
      zoomable: true,
      zoomOnTouch: true,
      zoomOnWheel: true,
      wheelZoomRatio: 0.1,
      cropBoxMovable: true,
      cropBoxResizable: true,
      toggleDragModeOnDblclick: true,
      minContainerWidth: 400,
      minContainerHeight: 300,
      minCanvasWidth: 40,
      minCanvasHeight: 30,
      minCropBoxWidth: 40,
      minCropBoxHeight: 30,
      
      ready: (e) => { this.cropReadyEvent(e) },
      cropstart: (e) => { this.cropStartEvent(e) },
      cropmove: (e) => { this.cropMoveEvent(e) },
      cropend: (e) => { this.cropEndEvent(e) },
      crop: (e) => { this.cropEvent(e) },
      zoom: (e) => { this.cropZoomEvent(e) }
    };

    let data = navParams.get("image64");
    if (data) {
      this.global.convertFileToDataURLviaFileReader(data).subscribe(
        base64 => {
          this.image64Data = base64;
        }
      );
      //this.image64Data = data;
    }
  }

  ngOnInit() { }

  ionViewDidEnter() {
    console.log(this.angularCropper);
    //console.log("this.angularCropper.imageURL=", this.angularCropper.imageUrl);
  }

  async cancel() {
    this.modalCtrl.dismiss();
  }

  async close() {
    if (this.imageEdited || this.changed) {
      const alert = await this.alertCtrl.create({
        subHeader: await this.translate.instant('COMMON.ATTENTION'),
        message: await this.translate.instant('COMMON.CLOSE-MESSAGE-SAVE'),
        buttons: [
          {
            text: await this.translate.instant('COMMON.NO'),
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              console.log('Confirm Cancel:', blah);
            }
          }, {
            text: await this.translate.instant('COMMON.YES'),
            handler: () => {
              this.cancel();
            }
          }
        ]
      });

      await alert.present();
    }
    else {
      this.save();
    }
  }
 
  async save() {
    await this.crop();
    this.modalCtrl.dismiss({
      image64: this.image64Data
    });
  }

  // -------------------------------------------------------------

  crop() {
    
    this.imageEdited = true;
    this.changed = true;

    let cropper = this.angularCropper.cropper.crop();
    console.log("CROP, cropper: ", cropper);

    this.undoList.push(this.image64Data);

    let croppedImgB64String: string = this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/png', 1.0);
    this.image64Data = croppedImgB64String;

    this.angularCropper.cropper.clear();
  }

  // Nimmt die Cropper-Markierung weg
  clear(){
    this.angularCropper.cropper.clear();
  }

  // Zurücksetzen aller Änderungen wie drehen, skalieren, verschieben usw.
  reset() {
    this.cropperRotation = 0;
    this.angularCropper.cropper.reset();
  
    this.undo(true);

    this.changed = false;
  }

  // Letzte Änderung komplett zurück setzen. JETZT nur noch zurück auf Ausgangszustand!
  undo( complete: boolean = false) {

    if ( !complete ) {
      this.image64Data = this.undoList.pop();
    }
    else {
      this.image64Data = this.undoList.shift();
      this.undoList = [];
    }

    if (this.undoList.length <= 0) {
      this.imageEdited = false;
    }

    this.changed = false;
  }

  // -------------------------------------------------------------

  zoomIn() {
    this.changed = true;
    this.angularCropper.cropper.zoom(0.1);
  }

  zoomOut() {
    this.changed = true;
    this.angularCropper.cropper.zoom(-0.1);
  }

  rotateLeft() {
    this.changed = true;
    this.angularCropper.cropper.rotate(-90);
  }

  rotateRight() {
    this.changed = true;
    this.angularCropper.cropper.rotate(90);
  }

  flipHorizontal() {
    this.changed = true;
    this.scaleValX = this.scaleValX * -1;
    this.angularCropper.cropper.scaleX(this.scaleValX);
  }

  flipVertical() {
    this.changed = true;
    this.scaleValY = this.scaleValY * -1;
    this.angularCropper.cropper.scaleY(this.scaleValY);
  }

  moveCropper(x: number, y: number) {
    this.changed = true;
    console.log("moveCropper", x, y);
    this.angularCropper.cropper.move(x, y);
  }

  updateRotation() {
    this.changed = true;
    this.angularCropper.cropper.rotateTo(this.cropperRotation);
  }

  setDragMode(mode){
    this.angularCropper.cropper.setDragMode(mode);
  }

  // Events
  cropReadyEvent( event: Event ) {
    console.log("cropper ready Event, ", event);
    this.clear();
  }

  cropStartEvent( event: Event ) {
    //console.log("cropper cropStartEvent, ", event);
  }

  cropMoveEvent( event: Event ) {
    //console.log("cropper cropMoveEvent, ", event);
  }

  cropEndEvent( event: Event ) {
    //console.log("cropper cropEndEvent, ", event);
  }

  cropEvent( event: Event ) {
    console.log("cropper cropEvent, ", event);

    if ( event && event["detail"] && (event["detail"]["height"] == 0 && event["detail"]["width"] == 0)) {
      this.cropperVisible = false;
    } 
    else {
      this.cropperVisible = true;
    }
  }

  cropZoomEvent( event: Event ) {
    console.log("cropper cropZoomEvent, ", event);
  }

}

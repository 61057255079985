import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { IonContent, Platform } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { AlertController } from '@ionic/angular';

import { ApiService } from '../../services/api.service';
import { DataService } from '../../services/data.service';
import { GlobalService } from '../../services/global.service';

import { environment } from '../../../environments/environment';

import { FilechooserComponent } from '../filechooser/filechooser.component';

import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { ToastController } from '@ionic/angular';

//import { TextToSpeech } from '@ionic-native/text-to-speech/ngx';
import { TextToSpeechService } from '../../services/tts.service';

import { ResponseBoxComponent } from './../../popovers/response-box/response-box.component';

import { ActivationEnd, Router } from '@angular/router';

//import { LoadingController } from '@ionic/angular';

import { TranslateService } from '@ngx-translate/core';

import { OwneroptionsComponent } from "../../popovers/owneroptions/owneroptions.component";

// Audio Record
// https://ionicframework.com/docs/native/media
import { Media, MediaObject, MEDIA_STATUS } from '@ionic-native/media/ngx';
import { MediaService } from '../../services/media.service';
import { File } from '@ionic-native/file/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';

// ???
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { LoadingBoxService } from 'src/app/services/loading-box.service';

@Component({
  selector: 'app-sequences',
  templateUrl: './sequences.component.html',
  styleUrls: ['./sequences.component.scss'],
})
export class SequencesComponent implements OnInit {

  //@ViewChild(Content, { static: false }) content: Content;
  @ViewChild('stepsContent', { static: false }) stepsContent: IonContent;

  avatarTypes: any[];

  me: any = {};
  objectOwner: boolean = false;
  edit: boolean = false;
  fileList: FileList
  sequence: any = {
    name: "",
    description: "",
    image: -1,
    owner: -1,
    location: -1,
    active: true, // IMMER "active" nach Entscheidung der Projektgruppe am 11.08.2020
    settings: {},
    steps: [],
    users: []
  }
  locations: any[] = [];
  users: any[] = [];
  usersFiltered: any[] = [];
  userInput: string = "";
  usersAsignedOnly: boolean = false;

  section: string = "info"; // info | steps | users

  mediaUrl: string = environment.mediaUrl;

  env: any = environment;

  maxSteps: number = 28;

  toast: HTMLIonToastElement = null;
  //modal: HTMLIonModalElement = null;
  //loading: HTMLIonLoadingElement = null;

  disableFooterButtons: boolean = false;

  maxPlainTextLength: number = 200; // Max. Anzahl Zeichen bei Einstellung "fontsize=large"
  maxPlainAvatarTextLength: number = 84; // Max. Anzahl Zeichen bei Einstellung "fontsize=large"

  // names
  nameSaved: string;
  nameList: string[];
  nameIsUnique: boolean = true;

  constructor(
    navParams: NavParams,
    public platform: Platform,
    public modalCtrl: ModalController,
    public apiSrv: ApiService,
    public dataSrv: DataService,
    public global: GlobalService,
    public alertCtrl: AlertController,
    public toastController: ToastController,
    private ttsService: TextToSpeechService,
    private router: Router,
    public alertController: AlertController,
    //public loadingController: LoadingController,
    public translate: TranslateService,
    @Inject(DOCUMENT) private doc: any,
    // Audio Record
    public media: Media,
    public mediaService: MediaService,
    private file: File,
    private webview: WebView,
    public sanitizer: DomSanitizer,
    public loadingBoxService: LoadingBoxService
  ) {

    let object = navParams.get("object");
    if (object) {
      this.edit = true;
      this.sequence = object;
      if (!this.sequence.settings) {
        this.sequence.settings = {
          location: -1
        }
      }
      this.nameSaved = this.sequence.name;
    }

    this.nameList = [];
    this.nameIsUnique = true;
    let names = navParams.get("nameList");
    if (names) {
      this.nameList = names;
    }

    // duplicated! 
    //this.init();
  }

  ionViewDidEnter() {
    this.init();
  }

  async init() {

    //this.global.selectIconCSS( document, "opacity", 1.0 );

    // reset preview user
    this.dataSrv.setItem("previewUser", {});

    // set saved section
    let lastSection = await this.dataSrv.getItem("section");
    if (lastSection && lastSection != "") {
      this.section = lastSection;
      await this.dataSrv.clearItem("section");
    }

    this.me = await this.dataSrv.getItem("me");
    if (this.edit == false) {
      this.objectOwner = true;
      this.sequence.owner = this.me.id;
    } else {
      //if (this.sequence.owner == this.me.id) {
      if (this.sequence.isEditable) {
        this.objectOwner = true;
      }
    }

    let useAssist = (this.me.settings && this.me.settings.assist && this.me.settings.assist != -1) ? this.me.settings.assist : 1;
    let avatarImageSamples: string[] = [
      "../../../assets/images/avatar_0" + useAssist + "_normal.gif",
      "../../../assets/images/avatar_0" + useAssist + "_important.gif",
      "../../../assets/images/avatar_0" + useAssist + "_happy.gif",
      "../../../assets/images/avatar_0" + useAssist + "_question.gif",
      "../../../assets/images/avatar_0" + useAssist + "_right.gif",
      "../../../assets/images/avatar_0" + useAssist + "_false.gif",
      "../../../assets/images/avatar_00_placeholder.png"
    ]

    this.avatarTypes = [
      { 'id': 'normal', 'name': this.translate.instant('SEQUENCES.COMPONENT.STEPS.OPTION-VALUES-AVATAR.normal'), 'thumb': avatarImageSamples[0] },
      { 'id': 'important', 'name': this.translate.instant('SEQUENCES.COMPONENT.STEPS.OPTION-VALUES-AVATAR.important'), 'thumb': avatarImageSamples[1] },
      { 'id': 'happy', 'name': this.translate.instant('SEQUENCES.COMPONENT.STEPS.OPTION-VALUES-AVATAR.happy'), 'thumb': avatarImageSamples[2] },
      { 'id': 'question', 'name': this.translate.instant('SEQUENCES.COMPONENT.STEPS.OPTION-VALUES-AVATAR.question'), 'thumb': avatarImageSamples[3] },
      { 'id': 'right', 'name': this.translate.instant('SEQUENCES.COMPONENT.STEPS.OPTION-VALUES-AVATAR.right'), 'thumb': avatarImageSamples[4] },
      { 'id': 'false', 'name': this.translate.instant('SEQUENCES.COMPONENT.STEPS.OPTION-VALUES-AVATAR.false'), 'thumb': avatarImageSamples[5] },
      { 'id': 'off', 'name': this.translate.instant('SEQUENCES.COMPONENT.STEPS.OPTION-VALUES-AVATAR.off'), 'thumb': avatarImageSamples[6] }
    ];

    if (this.sequence.steps.length == 0 && this.objectOwner == true) {
      this.sequence.steps.push({
        "uuid": this.global.generateUUID(),
        "title": "",
        "text": "",
        "image": -1,
        "audio": -1,
        "avatar": -1,
        "pictogram": -1,
        type: "",
        "questions" : []
      });
    }

    let token = await this.dataSrv.getItem("token");
    for (let step of this.sequence.steps) {

      // KEIN "Alternativer Sprechertext" mehr!
      step.tts = "";

      if (!step.avatar) {
        step.avatar = -1;
      }

      if (step.uuid == null) {
        step.uuid = this.global.generateUUID();
      }

      if (step.plainText == null) {
        await this.textChanged(step);
      }

      if (step.plainAvatarText == null) {
        await this.avatartextChanged(step);
      }

      step.showAvatartext = (step.avatartext != null && step.avatartext.length > 0);
      step.showTTSText = (step.tts != null && step.tts.length > 0);

      let imageFile = step.image;
      if (imageFile != -1) {
        let imageResponse = await this.apiSrv.doGet('media', { "token": token }, { "id": imageFile });
        if (imageResponse != null) {
          let imageObject = JSON.parse(JSON.stringify(imageResponse));
          if (imageObject.filename) {
            step.filename = imageObject.filename;
            step.mediatype = imageObject.type;
            step.medianame = imageObject.name;
          }
        }
      }

      console.log("step.audio=", step.audio);
      /*
      let audioFile = step.audio;
      let audioResponse = await this.apiSrv.doGet('media', { "token": token }, { "id": audioFile });
      if (audioResponse != null) {
        let audioObject = JSON.parse(JSON.stringify(audioResponse));
        if (audioObject.filename) {
          step.audiofile = audioObject.filename;
          step.audioname = audioObject.name;
        }
      }
      */
    }

    let locationsResponse = await this.apiSrv.doGet('locations', { "token": token }, {});
    //alert(JSON.stringify(locationsResponse));
    this.locations = JSON.parse(JSON.stringify(locationsResponse));
    for (let location of this.locations) {
      location.id = "id" + location.id;
    }
    if (this.sequence.location != -1) {
      this.sequence.location = (this.sequence.location.indexOf("id") < 0 ? "id" + this.sequence.location : this.sequence.location);
    }

    let usersResponse = await this.apiSrv.doGet('users', { "token": token }, {});
    //console.log("usersResponse: ", usersResponse);
    let users = JSON.parse(JSON.stringify(usersResponse));
    for (let user of users) {
      let userImageResponse = await this.apiSrv.doGet('media', { "token": token }, { "id": user.image });
      if (userImageResponse != null) {
        let userImageObject = JSON.parse(JSON.stringify(userImageResponse));
        if (userImageObject.filename) {
          user.userImage = userImageObject.filename;
        }
      }
      for (let asignedUser of this.sequence.users) {
        if (user.id == asignedUser) {
          user.asigned = true;
        }
      }
      if (user.maxrole == 'USER' || (this.objectOwner && user.id == this.me.id)) {
        this.users.push(user);
        // TUROR automatisch als Benutzer setzen
        if (user.id == this.me.id) {
          user.asigned = true;
        }
      }
    }

    // beim Start immer alle User
    //this.filterUsers();
    this.usersFiltered = this.users;

    console.log("sequences.components, init, user:", this.users);
    console.log("sequences.components, init, usersFiltered:", this.usersFiltered);
    console.log("sequences.components, init, sequence.steps:", this.sequence.steps);
  }

  setSection(section: string) {
    this.section = section;
  }

  checkName(name: string) {
    console.log("sequences.components: ", name, ", nameList: ", this.nameList, ", ", !(this.nameList.some(e => e === name)));
    this.nameIsUnique = !(this.nameList.some(e => e === name));
    if (this.nameSaved == name) {
      this.nameIsUnique = true;
    }
    this.disableFooterButtons = !this.nameIsUnique;
  }

  reorderStepItems(event) {
    console.log(event);
    console.log(`Moving item from ${event.detail.from} to ${event.detail.to}`);

    const itemMove = this.sequence.steps.splice(event.detail.from, 1)[0];
    this.sequence.steps.splice(event.detail.to, 0, itemMove);

    event.detail.complete();
  }

  async selectMedia() {
    if (this.objectOwner == false) {
      return;
    }

    const modal = await this.modalCtrl.create({
      component: FilechooserComponent,
      componentProps: {
        'image': this.sequence.image,
        'types': ['image'],
        'tags': 'sequence'
      },
      cssClass: 'filechooser-modal',
      backdropDismiss: false
    });

    modal.onDidDismiss().then((data) => {
      if (data && data.data && data.data.selected) {
        this.sequence.image = data.data.selected;
        this.sequence.filename = data.data.filename;
        //this.sequence.mediatype = data.data.mediatype;
      }
    });

    return await modal.present();
  }

  async selectImageForStep(step: any) {
    if (this.objectOwner == false) {
      return;
    }

    const modal = await this.modalCtrl.create({
      component: FilechooserComponent,
      componentProps: {
        'image': step.image,
        'types': ['image', 'video', 'pictogram'],
        'tags': 'sequence'
      },
      cssClass: 'filechooser-modal',
      backdropDismiss: false
    });

    modal.onDidDismiss().then((data) => {
      if (data && data.data && data.data.selected) {
        step.image = data.data.selected;
        step.filename = data.data.filename;
        step.mediatype = data.data.mediatype;
        step.medianame = data.data.medianame;
      }
    });

    return await modal.present();
  }

  /* ASH wegen Objekterkennung */
  async checkRecognition(step: any) {
    let context = this;
    if (step.recognition == true) {
      context.createTemplate(step.filename, step);
    }
  }

  async selectImageForRecognition(step: any) {
    let context = this;

    if (this.objectOwner == false) {
      return;
    }

    const modal = await this.modalCtrl.create({
      component: FilechooserComponent,
      componentProps: {
        'image': step.image,
        'types': ['image'],
        'tags': 'sequence'
      },
      cssClass: 'filechooser-modal',
      backdropDismiss: false
    });

    modal.onDidDismiss().then((data) => {
      if (data && data.data && data.data.selected) {
        step.recognitionimage = data.data.selected;
        step.recognitionfilename = data.data.filename;
        step.recognitionmedianame = data.data.medianame;

        context.createTemplate(step.recognitionfilename, step);
      }
    });

    return await modal.present();
  }

  async createTemplate(filename: string, step: any) {

    console.log("createTemplate: " + filename);

    await this.loadingBoxService.present('RECOGNITION-CREATE-TEMPLATE', this.translate.instant('SEQUENCES.COMPONENT.STEPS.RECOGNITION-CREATE-TEMPLATE'));

    let response = await this.apiSrv.doGet('test/template', {}, { name: filename, data: filename });
    let json = JSON.parse(JSON.stringify(response));
    console.log(json);

    await this.loadingBoxService.dismiss('RECOGNITION-CREATE-TEMPLATE');

    if (json.body == "Vorlage_Kam_An") {
      let recogitionUri = json.request.uri.href;
      step.recognitionuri = recogitionUri;
      //this.global.presentToast(this.translate.instant('SEQUENCES.COMPONENT.STEPS.RECOGNITION-CREATE-TEMPLATE-SUCCESS', { uri: recogitionUri } ));
      this.global.presentToast(this.translate.instant('SEQUENCES.COMPONENT.STEPS.RECOGNITION-CREATE-TEMPLATE-SUCCESS2'));
    } else {
      this.global.presentToast(this.translate.instant('SEQUENCES.COMPONENT.STEPS.RECOGNITION-CREATE-TEMPLATE-ERROR'));
    }
  }

  async stepAfter(stepObj: any) {
    let index: number = 0;
    for (let step of this.sequence.steps) {
      if (step == stepObj) {

        // close step
        if (step.active)
          this.handleSequenceSteps(step);

        this.sequence.steps.splice((index + 1), 0, {
          "uuid": this.global.generateUUID(),
          "title": "",
          "text": "",
          "image": -1,
          "audio": -1,

          "plainText": "",
          "showAvatartext": false,
          "showTTSText": false
        });

        // open new step
        this.handleSequenceSteps(this.sequence.steps[(index + 1)]);

        return;
      }
      index++;
    }
  }

  async deleteStep(stepObj: any) {
    let index: number = 0;
    for (let step of this.sequence.steps) {
      if (step == stepObj) {
        const alert = await this.alertCtrl.create({
          subHeader: await this.translate.instant('COMMON.ATTENTION'),
          message: await this.translate.instant('SEQUENCES.COMPONENT.STEPS.DELETE-STEP-QUESTION', { title: step.title }),
          buttons: [
            {
              text: await this.translate.instant('COMMON.NO'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: (blah) => {
                console.log('Confirm Cancel: blah');
              }
            }, {
              text: await this.translate.instant('COMMON.YES'),
              handler: () => {
                this.doDeleteStep(index);
              }
            }
          ]
        });

        await alert.present();
        return;
      }
      index++;
    }
  }

  async doDeleteStep(index: number) {
    this.sequence.steps.splice(index, 1);
  }

  async copyStep(stepObj: any) {
    let index: number = 0;
    for (let step of this.sequence.steps) {
      if (step == stepObj) {

        // close step
        if (step.active)
          this.handleSequenceSteps(step);

        console.log("COPY STEP:", step);

        let copyStep: Object = {};

        Object.keys(step).forEach(key => {
          //console.log(`key=${key}  value=${step[key]}`);
          copyStep[key] = step[key];
        });

        copyStep["title"] += await this.translate.instant('SEQUENCES.COMPONENT.STEPS.COPY-STEP-SUFFIX');
        copyStep["uuid"] = this.global.generateUUID();
        //copyStep["active"] = true;

        this.sequence.steps.splice((index + 1), 0, copyStep);

        /*
        this.sequence.steps.splice((index + 1), 0, {
          //"active": step.active,
          "audio": step.audio,
          "audiofile": step.audiofile,
          "audioname": step.audioname,
          "avatar": step.avatar,
          "avatartext": step.avatartext,
          "image": step.image,
          "filename": step.filename,
          "medianame": step.medianame,
          "mediatype": step.mediatype,
          "pictogram": step.pictogram,
          "plainText": step.plainText,
          "recognition": step.recognition,
          "showAvatartext": step.showAvatartext,
          "showTTSText": step.showTTSText,
          "text": step.text,
          "title": step.title + " (copy)",
          "tts": step.tts,
          "usetts": step.usetts,
          "uuid": this.global.generateUUID()
        });
        */

        console.log("COPIED STEP: ", this.sequence.steps[index + 1]);

        // open copied step
        this.handleSequenceSteps(this.sequence.steps[(index + 1)]);

        return;
      }
      index++;
    }
  }

  async saveObject() {

    await this.loadingBoxService.present('SAVE', this.translate.instant('SEQUENCES.COMPONENT.SAVE'));

    this.disableFooterButtons = true;

    this.sequence.location = ((typeof this.sequence.location === "string") ? this.sequence.location.replace(/id/ig, '') : this.sequence.location);

    this.sequence.users = [];
    for (let user of this.users) {
      if (user.asigned == true) {
        this.sequence.users.push(user.id);
      }
    }

    if (this.edit == false) {
      await this.createObject();
    } else {
      await this.modifyObject();
    }

    await this.loadingBoxService.dismiss('SAVE');

  }

  async completeObject() {

    await this.loadingBoxService.present('COMPLETE', this.translate.instant('SEQUENCES.COMPONENT.COMPLETE'));

    this.disableFooterButtons = true;

    this.sequence.location = ((typeof this.sequence.location === "string") ? this.sequence.location.replace(/id/ig, '') : this.sequence.location);
    this.sequence.users = [];
    for (let user of this.users) {
      if (user.asigned == true) {
        this.sequence.users.push(user.id);
      }
    }
    if (this.edit == false) {
      await this.createObject(true);
    } else {
      await this.modifyObject(true);
    }

    await this.loadingBoxService.dismiss('COMPLETE');

  }

  async createObject(closeModal: boolean = false) {

    let token = await this.dataSrv.getItem("token");
    let response = await this.apiSrv.doPost('scenes/create', { "token": token }, this.sequence);
    let json = JSON.parse(JSON.stringify(response));

    console.log("sequences.component, createObject, json=", json);
    //alert("sequences.component, createObject, json=" + JSON.stringify(json));

    // set id 
    if (json.id) {
      this.sequence.id = json.id;
    }
    //alert("createObject, this.sequence.id=" + this.sequence.id);

    if (json.error == null || json.error.code != 200) {
      this.global.presentToast(this.translate.instant('SEQUENCES.COMPONENT.SAVE-ERROR', { name: this.sequence.name }));
    }
    else { // json.error.code == 200

      this.global.presentToast(this.translate.instant('SEQUENCES.COMPONENT.SAVE-SUCCESS', { name: this.sequence.name }));

      if (closeModal && this.modalCtrl && await this.modalCtrl.getTop()) {
        this.modalCtrl.dismiss({
          error: 200
        });
      }
      else {
        // Nur einmal eine neue Aufgabe anlegen, danach nur noch "modify"...
        this.edit = true;
      }

      this.disableFooterButtons = false;

    }

    if (this.sequence.location != -1) {
      this.sequence.location = (this.sequence.location.indexOf("id") < 0 ? "id" + this.sequence.location : this.sequence.location);
    }
  }

  async modifyObject(closeModal: boolean = false) {
    let token = await this.dataSrv.getItem("token");

    console.log("sequences.components, modifyObject, this.sequence=", this.sequence);

    let response = await this.apiSrv.doPost('scenes/modify?id=' + this.sequence.id, { "token": token }, this.sequence);
    let json = JSON.parse(JSON.stringify(response));

    console.log("sequences.component, modifiyObject, json=", json);

    if (json.error == null || json.error.code != 200) {
      this.global.presentToast(this.translate.instant('SEQUENCES.COMPONENT.MODIFY-ERROR', { name: this.sequence.name }));
    }
    else { // json.error.code == 200

      this.global.presentToast(this.translate.instant('SEQUENCES.COMPONENT.MODIFY-SUCCESS', { name: this.sequence.name }));

      if (closeModal && this.modalCtrl && await this.modalCtrl.getTop()) {
        this.modalCtrl.dismiss({
          error: 200
        });
      }

      this.disableFooterButtons = false;
    }

    if (this.sequence.location != -1) {
      this.sequence.location = (this.sequence.location.indexOf("id") < 0 ? "id" + this.sequence.location : this.sequence.location);
    }
  }

  async deleteObject() {
    const alert = await this.alertCtrl.create({
      subHeader: await this.translate.instant('COMMON.ATTENTION'),
      message: await this.translate.instant('SEQUENCES.COMPONENT.DELETE-SEQUENCE-QUESTION', { name: this.sequence.name }),
      buttons: [
        {
          text: await this.translate.instant('COMMON.NO'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: (res) => {
            console.log('Confirm Cancel: deleteObject', res);
          }
        }, {
          text: await this.translate.instant('COMMON.YES'),
          handler: () => {
            this.doDelete();
          }
        }
      ]
    });

    await alert.present();
  }

  async doDelete() {

    await this.loadingBoxService.present('DELETE-SEQUENCE', this.translate.instant('SEQUENCES.COMPONENT.DELETE-SEQUENCE'));

    let token = await this.dataSrv.getItem("token");
    let response = await this.apiSrv.doPost('scenes/delete?id=' + this.sequence.id, { "token": token }, this.sequence);
    let json = JSON.parse(JSON.stringify(response));

    console.log("sequences.component, doDelete, json=", json);

    await this.loadingBoxService.dismiss('DELETE-SEQUENCE');

    if (!json || (json && json.code != 200)) {
      this.global.presentToast(this.translate.instant('SEQUENCES.COMPONENT.DELETE-SEQUENCE-ERROR', { name: this.sequence.name }));
    }
    else { // json.error.code == 200
      this.global.presentToast(this.translate.instant('SEQUENCES.COMPONENT.DELETE-SEQUENCE-SUCCESS', { name: this.sequence.name }));
      if (this.modalCtrl && await this.modalCtrl.getTop()) {
        this.modalCtrl.dismiss({
          error: 200
        });
      }
    }

  }

  async closeModal(demand: boolean = false) {
    if (demand) {
      const alert = await this.alertCtrl.create({
        subHeader: await this.translate.instant('COMMON.ATTENTION'),
        message: await this.translate.instant('SEQUENCES.COMPONENT.CLOSE-MESSAGE'),
        buttons: [
          {
            text: await this.translate.instant('COMMON.NO'),
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              console.log('Confirm Cancel: blah');
            }
          }, {
            text: await this.translate.instant('COMMON.YES'),
            handler: () => {
              this.dataSrv.setItem("previewUser", {});

              if (this.recordAudio) {
                this.stopRecordAudio();
              }

              this.loadingBoxService.dismiss();

              setTimeout(() => {
                this.modalCtrl.dismiss();
              }, 500);

            }
          }
        ]
      });

      await alert.present();
    }
    else {

      this.loadingBoxService.dismiss();

      setTimeout(() => {
        this.modalCtrl.dismiss();
      }, 500);

    }
  }

  ngOnInit() { }

  async addStep() {
    this.sequence.steps.push({
      "uuid": this.global.generateUUID(),
      "title": "",
      "text": "",
      "image": -1,
      "audio": -1
    });
  }

  handleSequenceSteps(currentStep: any) {
    let lastState = currentStep.active;
    for (let step of this.sequence.steps) {
      step.active = false;
    }
    currentStep.active = !lastState;
  }

  handleStepScroll(stepID: string) {

    setTimeout(() => {
      if (this.stepsContent) {
        let y: number = document.getElementById(stepID).offsetTop;
        console.log(stepID, ", offsetTop=", y);
        this.stepsContent.scrollToPoint(0, y);
      }
    }, 250);
  }

  decodeHTMLEntities(text: string) {
    const span: HTMLElement = document.createElement('span');

    return text
      .replace(/&[#A-Za-z0-9]+;/gi, (entity, position, text) => {
        span.innerHTML = entity;
        return span.innerText;
      });
  }

  async textChanged(stepObj: any) {
    if (stepObj.text) {

      stepObj.plainText = this.decodeHTMLEntities(stepObj.text.replace(/(&nbsp;|<([^>]+)>)/ig, ''));

      if (stepObj.plainText.length > this.maxPlainTextLength) {
        this.doc.execCommand('undo', false, null);
      }
    }
    else {
      stepObj.plainText = "";
    }
  }

  async avatartextChanged(stepObj: any) {
    if (stepObj.avatartext) {
      stepObj.plainAvatarText = this.decodeHTMLEntities(stepObj.avatartext.replace(/(&nbsp;|<([^>]+)>)/ig, ''));

      console.log(stepObj.avatartext.length, ", stepObj.avatartext=", stepObj.avatartext);
      console.log(stepObj.plainAvatarText.length, ",stepObj.plainAvatarText:", stepObj.plainAvatarText);

      if (stepObj.plainAvatarText.length > this.maxPlainAvatarTextLength) {
        this.doc.execCommand('undo', false, null);
      }
    }
    else {
      stepObj.plainAvatarText = "";
    }
  }

  async speak(txt: string) {
    //console.log("speak:", txt);

    txt = this.decodeHTMLEntities(txt.replace(/(&nbsp;|<([^>]+)>)/ig, ''));

    /*
    let options = {
      "text": txt,
      "locale": "de-DE"
    }
    this.tts.speak(options)
      .then(() => console.log('Success'))
      .catch((reason: any) => console.log(reason));
    */

    this.ttsService.speak(txt);
  }

  async switchView(bSave: boolean) {
    if (bSave) {
      await this.saveRecordedAudio(false);
    }

    await this.dataSrv.setItem("location", this.sequence.location);
    await this.dataSrv.setItem("locationObj", this.locations.find(item => item.id === "id" + this.sequence.location));

    await this.dataSrv.setItem("section", this.section);
    await this.dataSrv.setItem("sequenceid", this.sequence.id);

    setTimeout(() => {

      this.global.dismissToast();

      //alert(JSON.stringify(this.sequence));

      if (this.sequence.id) {

        setTimeout(() => {

          this.loadingBoxService.present('GENERATE-PREVIEW', this.translate.instant('SEQUENCES.COMPONENT.GENERATE-PREVIEW'));

          setTimeout(() => {

            if (this.modalCtrl && this.modalCtrl.getTop()) {
              this.modalCtrl.dismiss({
                error: 200
              });
            }

            setTimeout(() => {
              console.log("sequences.components, switchView to scene id=", this.sequence.id);
              this.loadingBoxService.dismiss('GENERATE-PREVIEW');
              this.router.navigate(['/scene/' + this.sequence.id]);
            }, 2000);

          }, 2000);

        }, 500);

      }
    }, 1000);

  }

  async presentAlertPromptCopySequnce(sequenceToCopy: any) {
    const alert = await this.alertController.create({
      cssClass: 'alert-class',
      header: await this.translate.instant('SEQUENCES.COMPONENT.COPY.HEADLINE'),
      inputs: [
        {
          name: 'name',
          type: 'text',
          value: name,
          placeholder: await this.translate.instant('SEQUENCES.COMPONENT.COPY.PREFIX')
        }
      ],
      buttons: [
        {
          text: await this.translate.instant('COMMON.CANCEL'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Abbruch "Aufgabe kopieren"');
          }
        }, {
          text: await this.translate.instant('COMMON.ASSUME'),
          handler: (data) => {
            console.log('Aufgabe ' + sequenceToCopy.name + '(mit id=' + sequenceToCopy.id + ') in ' + data.name + ' kopieren...');
            this.copySequence(sequenceToCopy, data.name);
          }
        }
      ]
    });

    alert.onDidDismiss().then((data) => {
      //this.initPage();
    });

    await alert.present();
  }

  async copySequence(sequenceToCopy: any, newName: string) {

    let newSequence: any = {
      name: newName,
      description: sequenceToCopy.description,
      image: sequenceToCopy.image,
      owner: -1,
      location: ((typeof sequenceToCopy.location === "string") ? sequenceToCopy.location.replace("id", "") : sequenceToCopy.location),
      active: false,
      settings: sequenceToCopy.settings,
      steps: [],
      users: [this.me.id]
    }

    for (let step of sequenceToCopy.steps) {
      newSequence.steps.push({
        "uuid": this.global.generateUUID(),
        "title": step.title,
        "text": step.text,
        "image": step.image,
        "audio": step.audio,
        "avatar": step.avatar,
        "pictogram": step.pictogram
      });
    }

    let token = await this.dataSrv.getItem("token");
    let response = await this.apiSrv.doPost('scenes/create', { "token": token }, newSequence);
    let json = JSON.parse(JSON.stringify(response));

    console.log("sequences.component, copySequence, json=", json);

    // set id 
    if (json.id) {
      newSequence.id = json.id;
    }

    if (json.error == null || json.error.code != 200) {
      this.global.presentToast(this.translate.instant('SEQUENCES.COMPONENT.COPY.ERROR', { name: this.sequence.name, namenew: newSequence.name }));
    }
    else { // json.error.code == 200
      this.global.presentToast(this.translate.instant('SEQUENCES.COMPONENT.COPY.SUCCESS', { name: this.sequence.name, namenew: newSequence.name }));
      if (this.modalCtrl && await this.modalCtrl.getTop()) {
        this.modalCtrl.dismiss({
          error: 200
        });
      }
    }

  }

  filterUsers() {

    this.usersFiltered = [];

    let temp: any[] = [];


    if (this.userInput == "") {
      temp = this.users;
    }
    else {
      let searchVal: string = this.userInput.toLowerCase();
      for (let object of this.users) {
        if (
          object.username
            .toLowerCase()
            .indexOf(searchVal) != -1 ||
          object.firstname
            .toLowerCase()
            .indexOf(searchVal) != -1 ||
          object.lastname
            .toLowerCase()
            .indexOf(searchVal) != -1
        ) {
          temp.push(object);
        }
      }
    }

    for (let object of temp) {
      if (!this.usersAsignedOnly || (this.usersAsignedOnly && object.asigned)) {
        this.usersFiltered.push(object);
      }
    }
  }

  async openOptions() {

    if (this.toast) {
      this.toast.dismiss();
    }

    /*

    const popover = await this.modalCtrl.create({
      component: OwneroptionsComponent,
      componentProps: {
        owners: this.showOwners,
        tutors: this.showTutors,
        users: this.showUsers,
        name: "Benutzer",
      },
      backdropDismiss: false,
    });

    popover.onDidDismiss().then((data) => {
      if (data && data.data) {
        this.showOwners = data.data.owners;
        this.showTutors = data.data.tutors;
        this.showUsers = data.data.users;
      }

      this.filterObjects();
    });

    return await popover.present();
    */
  }

  repairLocationId(id: string) {
    if (!id || id == "-1") return id;
    if (id.indexOf("id") == -1) {
      id = "id" + id;
    }
    return id;
  }

  // AUDIO RECORD

  recordAudioFile: MediaObject;
  recordAudio: boolean = false;
  recordAudioFinished: boolean = false;
  externalCacheDirectory: string = null;

  startRecordAudio(step: any) {

    console.log("startRecordAudio...");

    this.mediaReleaseRessources();

    this.saveUndoAudio(step);

    if (!this.externalCacheDirectory) {
      this.externalCacheDirectory = this.file.externalCacheDirectory;
      if (this.platform.is("ios")) {
        this.externalCacheDirectory = this.file.cacheDirectory;
      }
    }

    if (this.externalCacheDirectory && step.audioRecordFileName) {
      this.file.removeFile(this.externalCacheDirectory, step.audioRecordFileName)
        .then((res) => {
          console.log("removeFile, res=", res);
        },
          (err: Error) => {
            console.error("removeFile, err=", err);
          });
    }

    // set filename
    step.audioRecordFileName = this.global.generateUUID() + ".m4a";

    setTimeout(() => {
      this.startRecordAudioInside(step);
    }, 200);

  }

  startRecordAudioInside(step: any) {

    console.log("startRecordAudioInside...");

    this.recordAudio = true;

    if (!this.externalCacheDirectory) {
      this.externalCacheDirectory = this.file.externalCacheDirectory;
      if (this.platform.is("ios")) {
        this.externalCacheDirectory = this.file.cacheDirectory;
      }
    }

    let filename: string = this.externalCacheDirectory.replace(/^file:\/\//, '') + step.audioRecordFileName; // m4a
    console.log("filename:", filename);

    this.file.createFile(this.externalCacheDirectory, step.audioRecordFileName, false).then(pathToFile => {

      this.recordAudioFile = this.media.create(filename);

      console.log("this.recordAudioFile:", this.recordAudioFile);

      // fires when file status changes
      this.recordAudioFile.onStatusUpdate.subscribe(status => {
        console.log("onStatusUpdate:", status);
        if (status == this.media.MEDIA_STARTING) {
          console.log("starting");
        }
        else if (status == this.media.MEDIA_RUNNING) {
          console.log("running");
        }
        else if (status == this.media.MEDIA_STOPPED) {
          console.log("stopped");
        }
      });

      this.recordAudioFile.onSuccess.subscribe(() => {
        console.log('recordAudioFile, action is successful');
      });

      this.recordAudioFile.onError.subscribe(error => {
        console.log('recordAudioFile, error!', error);
      });

      this.recordAudioFinished = false;
      this.recordAudioFile.startRecord();

      console.log("pathToFile.nativeURL: ", pathToFile.nativeURL);
      console.log("pathToFile.toInternalURL: ", pathToFile.toInternalURL());

      step.audio = pathToFile.toInternalURL(); // cdvfile
      if (this.platform.is('ios')) {
        let fixedUrl: string = this.webview.convertFileSrc(pathToFile.nativeURL);
        let safeResourceUrl: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fixedUrl);
        step.audioRecordLocalFilePath = safeResourceUrl;
      }
      else {
        step.audio = pathToFile.toInternalURL(); // cdvfile
        step.audioRecordLocalFilePath = this.webview.convertFileSrc(pathToFile.nativeURL);
      }
      console.log("step.audio: ", step.audio);
      console.log("step.audioRecordLocalFilePath: ", step.audioRecordLocalFilePath);

    });

  }

  stopRecordAudio(step: any = null) {

    console.log("stopRecordAudio...");

    this.recordAudio = false;
    this.recordAudioFinished = true;
    this.recordAudioFile.stopRecord();

    setTimeout(() => {
      this.mediaReleaseRessources();
    }, 500);
  }

  @ViewChild("recordedAudioTag", { static: false }) public recordedAudioTagRef: ElementRef;
  @ViewChild("audioTag", { static: false }) public audioTagRef: ElementRef;

  deleteAudio(step: any) {
    this.mediaReleaseRessources();
    this.saveUndoAudio(step);
    step.audio = -1;
    if (this.recordedAudioTagRef)
      this.recordedAudioTagRef.nativeElement.src = '';
    if (this.audioTagRef)
      this.audioTagRef.nativeElement.src = '';
    if (step.audiofile)
      delete step.audiofile;
    if (step.audioname)
      delete step.audioname;
    if (step.audioRecordLocalFilePath)
      delete step.audioRecordLocalFilePath;
    if (step.audioRecordFileName)
      delete step.audioRecordFileName;
  }

  lastAudio: any = null;
  undoAudio(step: any) {

    if (this.lastAudio) {

      step.audio = this.lastAudio.audio;

      if (this.lastAudio.audioRecordLocalFilePath) {
        step.audioRecordLocalFilePath = this.lastAudio.audioRecordLocalFilePath;
        if (this.recordedAudioTagRef) {
          this.recordedAudioTagRef.nativeElement.src = step.audioRecordLocalFilePath;
        }
      }
      else {
        delete step.audioRecordLocalFilePath;
      }

      if (this.audioTagRef)
        this.audioTagRef.nativeElement.src = this.mediaUrl + step.audio;

      if (this.lastAudio.audioRecordFileName) {
        step.audioRecordFileName = this.lastAudio.audioRecordFileName;
      }
      else {
        delete step.audioRecordFileName;
      }
    }

    this.lastAudio = null;

  }

  saveUndoAudio(step: any) {
    this.lastAudio = {};
    this.lastAudio.audio = step.audio;
    if (this.recordAudioFile)
      this.lastAudio.recordAudioFile = this.recordAudioFile;
    if (step.audioRecordLocalFilePath) {
      this.lastAudio.audioRecordLocalFilePath = step.audioRecordLocalFilePath;
    }
    if (step.audioRecordFileName) {
      this.lastAudio.audioRecordFileName = step.audioRecordFileName;
    }
  }

  async saveRecordedAudio(isCompleteProcess: boolean = false) {

    await this.uploadRecordedAudio();

    if (isCompleteProcess) {
      this.completeObject();
    }
    else {
      this.saveObject();
    }

  }

  // https://stackoverflow.com/questions/57566675/ionic-4-recording-audio-and-send-to-server-file-has-been-corrupted

  async uploadRecordedAudio() {
    console.log("sequence.component, uploadRecordedAudio, this.recordAudioFile=", this.recordAudioFile);

    if (this.recordAudioFile) {

      await this.loadingBoxService.present('SAVE-DATA', await this.translate.instant('COMMON.SAVE-DATA'));

      let token = await this.dataSrv.getItem("token");
      for (let step of this.sequence.steps) {

        console.log("sequence.component, saveRecordedAudio, step.audio=", step.audio, " is string? ", (typeof step.audio === "string"));

        if (typeof step.audio === "string" && step.audio.indexOf("cdvfile") >= 0) {

          let formData: FormData = new FormData();

          let data: any = await this.mediaService.convertFileToBlob(step.audio);

          // upload
          formData.append('uploadFile', data.blob, step.audio.split(/.*[\/|\\]/)[1]); // statt 'data.blobName'als 3. Parameter
          let response = await this.apiSrv.doUpload('media/upload', { "token": token }, {}, formData);
          let json = JSON.parse(JSON.stringify(response));

          console.log("sequence.component, saveRecordedAudio (recordAudioFile), json.filename: ", json.filename);

          // set saved audio-filename
          if (json && json.filename) {
            step.audio = json.filename;
            if (step.audioRecordLocalFilePath)
              delete step.audioRecordLocalFilePath;
            if (step.audioRecordFileName)
              delete step.audioRecordFileName;
          }

        }

      }

      await this.loadingBoxService.dismiss('SAVE-DATA');

    }
  }


  mediaReleaseRessources() {
    if (this.recordAudioFile && this.recordAudioFile.getDuration() != -1) {
      console.log("mediaReleaseRessources");
      this.recordAudioFile.stop();
      this.recordAudioFile.release();
      delete this.recordAudioFile;
    }
  }

  checkboxClick(e, step){
    if (!step.questions) {
      step.questions = [];
    }
    if (e.detail.checked == true) {
      step.questions.push(e.detail.value);
    } else {
      const index = step.questions.indexOf(e.detail.value);
      if (index > -1) {
        step.questions.splice(index, 1);
      }
    }

    console.log(step.questions);
  }
}

import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingBoxService {

  constructor(public loadingController: LoadingController) {
  }

  async present(loadingID: string, message: string = null) {

    await this.dismiss();

    console.log('LoadingService, present, id=', loadingID, ', message=', message);

    const loading = await this.loadingController.create({
      id: loadingID,
      message: message
    });

    loading.onDidDismiss().then(() => {
      console.log('LoadingService, dismissed!');
    });
    return await loading.present();
  }

  async dismiss(loadingID: string = null) {
    console.log('LoadingService, dismiss, id=', loadingID);
    if (!loadingID) {
      return await this.loadingController.getTop().then(v => v ? this.loadingController.dismiss() : null);
    }
    else if (loadingID) {
      try {
        return await this.loadingController.dismiss(null, null, loadingID);
      }
      catch (e) {
        return await this.loadingController.dismiss();
      }
    }
  }

  async dismissAllLoaders() {

    const whilePromise = (
      condition: () => Promise<boolean>,
      action: () => Promise<boolean>
    ) => {
      condition().then(value => {
        if (value) {
          action().then(closed => {
            if (closed) {
              whilePromise(condition, action);
            } else {
              throw new Error("Could not dismiss the topmost loader. Aborting...");
            }
          });
        }
      });
    };

    whilePromise(
      () => this.loadingController.getTop().then(topLoader => topLoader != null),
      () => this.loadingController.dismiss()
    );

  }
}

import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-mediaoptions',
  templateUrl: './mediaoptions.component.html',
  styleUrls: ['./mediaoptions.component.scss'],
})
export class MediaoptionsComponent implements OnInit {

  users: boolean = true;
  locations: boolean = true;
  sequences: boolean = true;
  pictograms: boolean = true;
  avatars: boolean = true;
  owners: boolean = false;
  folders: boolean = true;

  showImages: boolean = true;
  showVideos: boolean = true;
  showAudios: boolean = true;  

  types: string[] = [];

  constructor(
    navParams: NavParams,
    public modalCtrl: ModalController,
    public translate: TranslateService
  ) {
    let types = navParams.get("types");
    if (types) {
      this.types = types;
    }
    else {
      //this.types = ['image', 'video', 'audio'];
      this.types = ['image', 'video'];
    }
  }

  saveOptions() {
    this.modalCtrl.dismiss({
      'users': this.users,
      'locations': this.locations,
      'sequences': this.sequences,
      'pictograms': this.pictograms,
      'avatars': this.avatars,
      'owners': this.owners,
      'folders': this.folders,
      'showImages': this.showImages,
      'showVideos': this.showVideos,
      'showAudios': this.showAudios
    })
  }
  closeModal() {
    this.modalCtrl.dismiss();
  }

  ngOnInit() { }

}

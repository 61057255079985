// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

let bridgeUrl: string = "";
let mediaUrl: string = "";

// server (default)
// bridgeUrl = "https://api.dibass.mit.de/api/";
// mediaUrl = "https://api.dibass.mit.de/uploads/";

// Mandant IW
//bridgeUrl = "https://api-iw.dibass.mit.de/api/";
//mediaUrl = "https://api-iw.dibass.mit.de/uploads/";

// server (stage)
// bridgeUrl = "https://api-stage.dibass.mit.de/api/";
// mediaUrl = "https://api-stage.dibass.mit.de/uploads/";

// server (master)
// bridgeUrl = "https://api-master.dibass.mit.de/api/";
// mediaUrl = "https://api-master.dibass.mit.de/uploads/";

// local
//bridgeUrl = "http://127.0.0.1:3000/api/";
//mediaUrl = "http://127.0.0.1:3000/uploads/";

// KS (local for tablet)
//bridgeUrl = "http://192.168.178.74:3000/api/";
//mediaUrl = "http://192.168.178.74:3000/uploads/";

// ASH (local for tablet)
// bridgeUrl = "http://192.168.2.108:3000/api/";
// mediaUrl = "http://192.168.2.108:3000/uploads/";

// server (demo5)
// bridgeUrl = "https://api-demo5.dibass.mit.de/api/";
// mediaUrl = "https://api-demo5.dibass.mit.de/uploads/";

// server (demo6)
// bridgeUrl = "https://api-demo6.dibass.mit.de/api/";
// mediaUrl = "https://api-demo6.dibass.mit.de/uploads/";

// server (demo7)
// bridgeUrl = "https://api-demo7.dibass.mit.de/api/";
// mediaUrl = "https://api-demo7.dibass.mit.de/uploads/";

// server (demo8)
bridgeUrl = "https://api-demo8.dibass.mit.de/api/";
mediaUrl = "https://api-demo8.dibass.mit.de/uploads/";

// server (demo9)
// bridgeUrl = "https://api-demo9.dibass.mit.de/api/";
// mediaUrl = "https://api-demo9.dibass.mit.de/uploads/";

// server (demo10)
// bridgeUrl = "https://api-demo10.dibass.mit.de/api/";
// mediaUrl = "https://api-demo1.dibass.mit.de/uploads/";

// server (demo11)
// bridgeUrl = "https://api-demo11.dibass.mit.de/api/";
// mediaUrl = "https://api-demo11.dibass.mit.de/uploads/";

// server (demo12)
// bridgeUrl = "https://api-demo12.dibass.mit.de/api/";
// mediaUrl = "https://api-demo12.dibass.mit.de/uploads/";

// server (demo13)
// bridgeUrl = "https://api-demo13.dibass.mit.de/api/";
// mediaUrl = "https://api-demo13.dibass.mit.de/uploads/";

// server (demo14)
// bridgeUrl = "https://api-demo14.dibass.mit.de/api/";
// mediaUrl = "https://api-demo14.dibass.mit.de/uploads/";

// server (demo15)
// bridgeUrl = "https://api-demo15.dibass.mit.de/api/";
// mediaUrl = "https://api-demo15.dibass.mit.de/uploads/";

// server (demo16)
// bridgeUrl = "https://api-demo16.dibass.mit.de/api/";
// mediaUrl = "https://api-demo16.dibass.mit.de/uploads/";

export const environment = {
  production: false,
  recognition: true,
  bridgeUrl: bridgeUrl,
  mediaUrl: mediaUrl
};


/*
export const environment = {
  production: false,
  _bridgeUrl: "http://172.168.178.74:3000/api/",
  _mediaUrl: "http://172.168.178.74:3000/uploads/",
  bridgeUrl: "http://127.0.0.1:3000/api/",
  mediaUrl: "http://127.0.0.1:3000/uploads/",
  ___bridgeUrl: "https://api.dibass.mit.de/api/",
  ___mediaUrl: "https://api.dibass.mit.de/uploads/"
};
*/

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

/*
ADMIN:
Username: admin
Password: a4dEm?o
 
Tutor:
Username: tutor
Password: t4dEm?o
 
Tutorin:
Username: tutorin
Password: t4dEm?o
 
Benutzer/Beschäftigter:
Username: benutzer
Password: b4dEm?o
 
Benutzerin/Beschäftigte:
Username: benutzerin
Password: b4dEm?o
*/
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TextToSpeechService } from '../../services/tts.service';
import { NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-recognation-callback-wait',
  templateUrl: './recognation-callback-wait.component.html',
  styleUrls: ['./recognation-callback-wait.component.scss'],
})
export class RecognationCallbackWaitComponent implements OnInit {

  bgcolor: any = "#FF0000";
  preverence: string;
  result: any;
  
  explanationText: string;

  me : any;

  constructor(
    public modalCtrl: ModalController,
    private ttsService: TextToSpeechService,
    navParams: NavParams,
    public translate: TranslateService
  ) {
    this.me = navParams.get("me");
  }

  async initModal() {

    // KS, 28.04.2021
    // KEINE Anzeige der result.msg, außer es handelt sich im die Info das es im Browser grundsätzlich nicht funktioniert!
    if (!("error" in this.result) || this.result.error === false) {
      this.result.msg_org = this.result.msg;
      this.result.msg = "";
    }

    if (this.me.settings.audio == true) {

      // KS, 28.04.2021
      // Keine Integration des "resultsting", da der Bilderkennungsserver nicht in jeder Konstellation das korrekte Ergebnis liefert.
      // Es kann vorkommen, dass der Server etwas als Korrekt bewertet, was es jedoch nicht ist (Stichwort: Phantomobjekte) oder etwas als
      // komplett Falsch, obwohl z.B. ein Objekt vorhanden und korrekt positioniert. Im Moment liefert der Server bei < 2 Objekten auf dem Prüfbild
      // immer ein Ergbnis das aussagte, die Bilderkennung hätte NICHT FUNKTIONIERT.
      
      let text = this.result.msg + " " +        
      // Sprechblase
      this.translate.instant('SCENE.RECOGNITION-RESPONSE.TEXT1') + " " +
      this.translate.instant('SCENE.RECOGNITION-RESPONSE.TEXT2') + " " +
      this.translate.instant('SCENE.RECOGNITION-RESPONSE.TEXT3') + " " +
      this.translate.instant('SCENE.RECOGNITION-RESPONSE.TEXT4') + " " +
      this.translate.instant('SCENE.RECOGNITION-RESPONSE.TEXT5') + " " +
        this.translate.instant('SCENE.RECOGNITION-RESPONSE.TEXT6');
      
      text = this.decodeHTMLEntities(text.replace(/(&nbsp;|<([^>]+)>)/ig, ''));

      this.ttsService.speak(text);
    }
  }

  decodeHTMLEntities(text: string) {
    const span: HTMLElement = document.createElement('span');

    return text
      .replace(/&[#A-Za-z0-9]+;/gi, (entity, position, text) => {
        span.innerHTML = entity;
        return span.innerText;
      });
  }

  closeModal(next: boolean) {
    this.modalCtrl.dismiss({
      'next': next
    });
  }

  ngOnInit() {
    this.initModal();
  }

}

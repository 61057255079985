import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'home-tutor',
    loadChildren: () => import('./home-tutor/home-tutor.module').then(m => m.HomeTutorPageModule)
  },
  {
    path: 'list',
    loadChildren: () => import('./list/list.module').then(m => m.ListPageModule)
  },
  {
    path: 'login',
    loadChildren: './login/login.module#LoginPageModule'
  },
  { path: 'locations', loadChildren: './locations/locations.module#LocationsPageModule' },
  { path: 'users', loadChildren: './users/users.module#UsersPageModule' },
  { path: 'sequences', loadChildren: './sequences/sequences.module#SequencesPageModule' },
  {
    path: 'sequences/:id',
    loadChildren: './sequences/sequences.module#SequencesPageModule'
  },
  { path: 'media', loadChildren: './media/media.module#MediaPageModule' },
  {
    path: 'scene/:id',
    loadChildren: './scene/scene.module#ScenePageModule'
  },
  {
    path: 'color-picker',
    loadChildren: './popovers/color-picker/color-picker.module#ColorPickerPageModule'
  }
  /*,
  { path: 'home-tutor', loadChildren: './home-tutor/home-tutor.module#HomeTutorPageModule' }
  */

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

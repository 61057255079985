import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-selectpage',
  templateUrl: './select.page.html',
  styleUrls: ['./select.page.scss'],
})
export class SelectPage implements OnInit {

  selected: any;  
  values: any;
  title: string;

  constructor(
    navParams: NavParams,
    public translate: TranslateService,
    public modalCtrl: ModalController
  ) {
    this.values = navParams.get("values");
    this.title = navParams.get("title");
    this.selected = navParams.get("selected");
  }

  ngOnInit() {
  }

  async saveObject() {
    this.modalCtrl.dismiss({
      'selected': this.selected
    });
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}

import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';

import { CallNumber } from '@ionic-native/call-number/ngx';

import { DataService } from '../services/data.service';

import { environment } from '../../environments/environment';

@Component({
  selector: 'app-tutor',
  templateUrl: './tutor.component.html',
  styleUrls: ['./tutor.component.scss'],
})
export class TutorComponent implements OnInit {

  me: any = null;
  mediaUrl: string = environment.mediaUrl;

  constructor(
    public dataSrv: DataService,
    public modalCtrl: ModalController,
    private callNumber: CallNumber,
    public alertCtrl: AlertController
  ) {
    this.initModal();
  }

  ngOnInit() {
  }

  ionViewDidEnter() {
    this.initModal();
  }

  async initModal() {
    this.me = await this.dataSrv.getItem("me");
    console.log("TutorComponent, me: ", this.me);
  }

  async doCall() {
    const alert = await this.alertCtrl.create({
      message: 'Möchtest Du wirklich ' + this.me.advisorname + ' anrufen?',
      buttons: [
        {
          text: 'Nein',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
            this.closeModal();
          }
        }, {
          text: 'Ja',
          handler: () => {
            console.log('Confirm Okay');
            this.doCallGo();
          }
        }
      ]
    });

    await alert.present();
  }

  doCallGo() {
    this.callNumber.callNumber(this.me.advisorphone, true)
      .then(res => console.log('Launched dialer!', res))
      .catch(err => console.log('Error launching dialer', err));
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

}

import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-recognation',
  templateUrl: './recognation.component.html',
  styleUrls: ['./recognation.component.scss'],
})
export class RecognationComponent implements OnInit {

  bgcolor: any = "#FF0000";
  template: string;

  constructor(
    navParams: NavParams,
    public modalCtrl: ModalController,
    public translate: TranslateService
  ) {
    this.initModal();
  }

  async initModal() {
  }

  openRecognition() {
    this.modalCtrl.dismiss({
      'dorecognition': true
    })
  }
  closeModal() {
    this.modalCtrl.dismiss({
      'dorecognition': false
    })
  }

  ngOnInit() { }

}

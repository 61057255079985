import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { AlertController } from '@ionic/angular';

import { ApiService } from '../../services/api.service';
import { DataService } from '../../services/data.service';

import { environment } from '../../../environments/environment';

import { FilechooserComponent } from '../filechooser/filechooser.component';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss'],
})
export class LocationsComponent implements OnInit {

  me: any = {};
  objectOwner: boolean = false;
  edit: boolean = false;
  fileList: FileList
  location: any = {
    name: "",
    description: "",
    image: -1,
    owner: -1,
    settings: {}
  }

  mediaUrl: string = environment.mediaUrl;

  constructor(
    navParams: NavParams,
    public modalCtrl: ModalController,
    public apiSrv: ApiService,
    public dataSrv: DataService,
    public alertCtrl: AlertController,
    public translate: TranslateService
  ) {
    let object = navParams.get("object");
    if (object) {
      this.edit = true;
      this.location = object;
      console.log(this.location);
    }

    this.init();
  }

  async init() {
    this.me = await this.dataSrv.getItem("me");
    if (this.edit == false) {
      this.objectOwner = true;
      this.location.owner = this.me.id;
    } else {
      //if (this.location.owner == this.me.id) {
      if (this.location.isEditable) {
        this.objectOwner = true;
      }
    }
  }

  async selectMedia() {
    if (this.objectOwner == false) {
      return;
    }

    const modal = await this.modalCtrl.create({
      component: FilechooserComponent,
      componentProps: {
        'image': this.location.image,
        'types': ['image'],
        'tags': 'location'
      },
      cssClass: 'filechooser-modal',
      backdropDismiss: false
    });

    modal.onDidDismiss().then((data) => {
      if (data && data.data && data.data.selected) {
        this.location.image = data.data.selected;
        this.location.filename = data.data.filename;
      }
    });

    return await modal.present();
  }

  fileChange(event) {
    this.fileList = event.target.files;
  }

  async saveObject() {
    if (this.edit == false) {
      this.createObject();
    } else {
      this.modifyObject();
    }
  }

  async createObject() {
    let token = await this.dataSrv.getItem("token");
    let response = await this.apiSrv.doPost('locations/create', { "token": token }, this.location);
    let json = JSON.parse(JSON.stringify(response));

    if (json.error.code == 200) {
      this.modalCtrl.dismiss({
        error: 200
      });
    }
  }

  async modifyObject() {
    let token = await this.dataSrv.getItem("token");
    let response = await this.apiSrv.doPost('locations/modify?id=' + this.location.id, { "token": token }, this.location);
    let json = JSON.parse(JSON.stringify(response));

    if (json.error.code == 200) {
      this.modalCtrl.dismiss({
        error: 200
      });
    }
  }

  async deleteObject() {
    const alert = await this.alertCtrl.create({
      subHeader: await this.translate.instant('COMMON.ATTENTION'),
      message: await this.translate.instant('LOCATIONS.COMPONENT.DELETE-LOCATION-QUERY', { name: this.location.name }),
      buttons: [
        {
          text: await this.translate.instant('COMMON.NO'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: await this.translate.instant('COMMON.YES'),
          handler: () => {
            this.doDelete();
          }
        }
      ]
    });

    await alert.present();
  }

  async doDelete() {
    let token = await this.dataSrv.getItem("token");
    let response = await this.apiSrv.doPost('locations/delete?id=' + this.location.id, { "token": token }, this.location);
    let json = JSON.parse(JSON.stringify(response));

    if (json.code == 200) {
      this.modalCtrl.dismiss({
        error: 200
      });
    }
  }

  async closeModal(demand: boolean = false) {
    if (demand) {
      const alert = await this.alertCtrl.create({
        subHeader: await this.translate.instant('COMMON.ATTENTION'),
        message: await this.translate.instant('LOCATIONS.COMPONENT.CLOSE-MESSAGE'),
        buttons: [
          {
            text: await this.translate.instant('COMMON.NO'),
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              console.log('Confirm Cancel: blah');
            }
          }, {
            text: await this.translate.instant('COMMON.YES'),
            handler: () => {
              this.modalCtrl.dismiss();
            }
          }
        ]
      });

      await alert.present();
    }
    else {
      this.modalCtrl.dismiss();
    }
  }

  ngOnInit() { }

}
